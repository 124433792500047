import React, { useEffect, useRef, useState } from 'react';
import { FiEdit, FiFilter } from 'react-icons/fi';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
} from 'reactstrap';
import { Form } from '@unform/web';
import { Input } from '../../components/unform/index';
import api from '../../services/api';
import { useValidator } from '../../hooks';

function adicionaZero(numero) {
  if (numero <= 9) {
    return `0${numero}`;
  }
  return numero;
}

function formataData(data) {
  const date = new Date(data);

  return `${adicionaZero(date.getDate().toString())}/${adicionaZero(
    date.getMonth() + 1
  ).toString()}/${date.getFullYear()} - ${adicionaZero(
    date.getHours()
  )}:${adicionaZero(date.getMinutes())}`;
}

function calculaDiasAtraso(data) {
  const date1 = new Date(data);
  const hoje = new Date();

  return Math.floor(Math.abs(hoje - date1) / (1000 * 60 * 60 * 24));
}

function Clientes() {
  const { showLoader, closeLoader, toast } = useValidator();

  const filtrosRef = useRef();

  const [filtro, setFiltro] = useState({});
  const [dados, setDados] = useState([]);

  async function getDados(page) {
    showLoader();
    try {
      const params = Object.keys(filtro)
        .map((e) => {
          if (e === 'page') {
            if (Number.isNaN(filtro[e])) {
              return `${e}=${1}`;
            }
          }
          return `${e}=${filtro[e]}`;
        })
        .join('&');

      const { data } = await api.get(
        `/pedidos-atrasados?page=${page || 1}&${params}`
      );

      setDados(data);
    } catch (err) {
      toast('Erro ao buscar dados!', { type: 'error' });
    }
    closeLoader();
  }

  useEffect(() => {
    getDados();
  }, [filtro]);

  let timeoutConsulta;
  function filtrarConsulta(e, value) {
    setFiltro({ ...filtro, [value]: e });
  }
  const debounceConsulta = async (body, value) =>
    new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body, value));
      }, 500);
    });

  return (
    <>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {dados?.count || 0}</h6>
          {/* <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter color="rgba(var(--theme-color-rgb), 0.6)" size={20} />
            &nbsp;&nbsp;Filtros
          </Button> */}
        </div>
        {/* <div className="filter-collapse px-3 pb-3">
          <UncontrolledCollapse toggler="#toggler">
            <Form onSubmit={(data) => console.log(data)} ref={filtrosRef}>
              <Row>
                <Col>
                  <Input
                    className="form-control"
                    placeholder="Nome"
                    name="nome"
                    onChange={(e) => debounceConsulta(e.target.value, 'nome')}
                  />
                </Col>
                <Col>
                  <Input
                    className="form-control"
                    placeholder="CPF"
                    name="cpf"
                    onChange={(e) => debounceConsulta(e.target.value, 'cpf')}
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div> */}

        <Table className="rwd-table" responsive>
          <thead>
            <tr>
              <th>ID Pedido</th>
              <th>Data Pagamento</th>
              <th>Parceiro</th>
              <th>Parceiro ID</th>
              <th>CNPJ</th>
              <th>Telefone</th>
              <th>Email</th>
              <th className="text-center">Dias de atraso</th>
            </tr>
          </thead>
          <tbody>
            {dados?.data?.map((m) => (
              <tr key={m?.id}>
                <td data-label="ID Pedido">{m?.Pedido?.id}</td>
                <td data-label="Data Pagamento">
                  {formataData(m?.data_pagamento)}
                </td>
                <td data-label="Parceiro">
                  {m?.Pedido?.Parceiro?.nome_fantasia}
                </td>
                <td data-label="Parceiro ID">{m?.Pedido?.Parceiro?.id}</td>
                <td data-label="CNPJ">{m?.Pedido?.Parceiro?.cnpj}</td>
                <td data-label="Telefone">{m?.Pedido?.Parceiro?.telefone}</td>
                <td data-label="Email">
                  {m?.Pedido?.Parceiro?.Usuario?.email}
                </td>
                <td data-label="Dias de atraso" className="text-center">
                  {calculaDiasAtraso(m?.data_pagamento)}
                </td>
                {/* <td
                  data-label="Action"
                  className="d-flex justify-content-lg-end justify-content-sm-between"
                >
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color="link"
                      className="text-decoration-none padding-0-5"
                    >
                      <FiSettings className="mr-2" /> <FaChevronDown />
                    </DropdownToggle>
                    <DropdownMenu end>
                      <DropdownItem
                        onClick={() => ativaInativa(m?.id, m?.status)}
                      >
                        <FiToggleLeft className="mr-1" />
                        Inativar/Ativar
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td> */}
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados?.pages || 1}
          onPageChange={({ selected }) => getDados(selected)}
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
    </>
  );
}

export default Clientes;
