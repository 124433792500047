import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import {
  Button,
  Card,
  Col,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
} from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
import { HiPlus } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  Input,
  Select,
  CheckBox,
  NumberFormat,
  ReactSelectAsyncCreatable,
  TextArea,
  Image,
} from '../../components/unform';
import api from '../../services/api';
import { queryApi } from '../../services/queryApi';
import { useValidator } from '../../hooks';
import useSearch from '../../utils/searchParams';
import { useTheme } from '../../contexts/theme';

const selecione = [{ value: '', label: 'Selecione' }];

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function CriarProduto() {
  const formRef = useRef();
  const isbnRef = useRef();
  const history = useHistory();
  const search = useSearch();
  const { setTitle } = useTheme();
  const { showLoader, closeLoader, toast } = useValidator();
  const { data = [] } = useQuery(['/options/categorias'], queryApi, {
    refetchOnWindowFocus: false,
  });
  const [categoria, setCategoria] = useState({
    tipo: 'Livro',
    sub: '',
    secao: '',
    estante: '',
    status: '',
  });
  const [offcanvas, setOffcanvas] = useState(null);
  const [foto, setFoto] = useState({
    foto1: null,
    foto2: null,
    foto3: null,
  });
  const selecetOptions = useRef({ visible: {} });
  const subtipo = data?.find((f) => f.value === categoria.tipo)?.sub;
  const secao = subtipo?.find((f) => f.value === categoria.sub)?.secao;
  const estante =
    data?.find((f) => f.value === categoria.tipo)?.estante ||
    subtipo?.find((f) => f.value === categoria.sub)?.estante ||
    secao?.find((f) => f.value === categoria.secao)?.estante;

  async function filtrarConsulta({ value, url }) {
    const { data: res } = await api.get(`${url}?value=${value}`);
    return res;
  }

  const toggleOffcanvas = () => setOffcanvas(!offcanvas);

  const loadOptions = async ({ type, ...rest }) =>
    new Promise((resolve) => {
      clearTimeout(selecetOptions.current[type]);

      selecetOptions.current[type] = setTimeout(() => {
        resolve(filtrarConsulta(rest));
      }, 500);
    });

  async function handleSubmitCreate(formData, manterInfos) {
    try {
      showLoader();
      await api.post('/produtos', formData);
      closeLoader();
      if (manterInfos === true) {
        toast('Produto cadastrado com sucesso!', { type: 'success' });
      } else {
        history.goBack();
      }
    } catch (err) {
      toast(err.mensagem);
      formRef.current.setErrors(err.errors);
      closeLoader();
    }
  }

  async function handleSubmitEdit(formData) {
    formRef.current.setErrors({});
    showLoader();
    try {
      await api.put(`/produtos/${search?.id}`, formData);
      closeLoader();
      toast('Produto alterado com sucesso', { type: 'success' });
      history.goBack();
    } catch (err) {
      toast(err.mensagem || err.message);
      if (err.errors) {
        formRef.current.setErrors(err.errors);
      }
      closeLoader();
    }
  }

  async function handleSubmit(e) {
    const formData = new FormData();
    Object.entries(e).forEach(([key, value]) => {
      if (value || typeof value === 'boolean' || typeof value === 'number') {
        formData.append(key, value);
      }
    });

    if (foto.foto1?.file) {
      formData.append('file', foto.foto1?.file, `1@${foto.foto1.file.name}`);
    } else if (foto.foto1?.id && !foto.foto1?.naoMudou) {
      formData.append('id_imagem_1', foto.foto1.id);
    } else if (foto.foto1?.remover) {
      formData.append('remover_imagem_1', true);
    }

    if (foto.foto2?.file) {
      formData.append('file', foto.foto2.file, `2@${foto.foto2.file.name}`);
    } else if (foto.foto2?.id && !foto.foto2?.naoMudou) {
      formData.append('id_imagem_2', foto.foto2.id);
    } else if (foto.foto2?.remover) {
      formData.append('remover_imagem_2', true);
    }

    if (foto.foto3?.file) {
      formData.append('file', foto.foto3.file, `3@${foto.foto3.file.name}`);
    } else if (foto.foto3?.id && !foto.foto3?.naoMudou) {
      formData.append('id_imagem_3', foto.foto3?.id);
    } else if (foto.foto3?.remover) {
      formData.append('remover_imagem_3', true);
    }

    formRef.current.setErrors({});

    if (search?.id) {
      handleSubmitEdit(formData);
    } else {
      handleSubmitCreate(formData, e?.manter_infos);
    }
  }

  async function openOffcanvas(ordem) {
    const { isbn, titulo } = formRef.current.getData();
    if (!isbn && !titulo) {
      return toast('Informe o título do produto ou seu ISBN');
    }

    showLoader();
    const { data: fotos } = await api
      .get(`/imagens?isbn=${isbn}&titulo=${titulo}`)
      .catch(() => {});
    closeLoader();

    setOffcanvas({
      fotos,
      ordem,
    });
  }

  async function handleCreate(nome, tipo) {
    try {
      const { data: option } = await api.post(
        tipo === 'autor' ? '/autores' : '/editoras',
        {
          nome,
        }
      );
      formRef.current.setFieldValue(`id_${tipo}`, {
        label: option.nome || nome,
        value: option.id,
      });
    } catch (err) {
      toast(err.mensagem);
    }
  }

  async function verifyData(val) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const { data: p } = await api.get(`/produtos/${val}/isbn`);
      if (!p) {
        Swal.close();
        return toast('Nenhum produto encontrado', { type: 'warning' });
      }
      setCategoria({
        tipo: p.tipo,
        sub: p.subtipo,
        secao: p.secao,
        estante: p.estante,
        status: p.status,
      });
      const tFoto1 = p.ProdutoImagems.find((f) => f.ordem === 1)?.Imagem;
      const tFoto2 = p.ProdutoImagems.find((f) => f.ordem === 2)?.Imagem;
      const tFoto3 = p.ProdutoImagems.find((f) => f.ordem === 3)?.Imagem;
      const bFotos = {
        foto1: null,
        foto2: null,
        foto3: null,
      };
      if (tFoto1) {
        bFotos.foto1 = {
          ...tFoto1,
        };
      }
      if (tFoto2) {
        bFotos.foto2 = {
          ...tFoto2,
        };
      }
      if (tFoto3) {
        bFotos.foto3 = {
          ...tFoto3,
        };
      }
      setFoto(bFotos);
      setTimeout(() => {
        formRef.current.setData({
          ...p,
          preco_normal: null,
          preco_desconto: null,
          descricao_estado: null,
          estoque_disponivel: null,
          estoque_minimo: null,
          id_autor: p.Autor
            ? {
                label: p.Autor.nome,
                value: p.Autor.id,
              }
            : null,
          id_editora: p.Editora
            ? {
                label: p.Editora.nome,
                value: p.Editora.id,
              }
            : null,
        });
        Swal.close();
      }, 200);
    } catch (err) {
      toast(err.mensagem);
      Swal.close();
    }
  }

  async function askVerifyData(val) {
    swalWithBootstrapButtons
      .fire({
        title: 'ISBN informado',
        text: 'Deseja carregar este produto com dados existente a partir do ISBN?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: 'Aguarde',
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          verifyData(val);
        }
      });
  }

  useEffect(() => {
    async function getProduto(id) {
      showLoader();
      setTitle('Edição de produto');
      try {
        const { data: p } = await api.get(`/produtos/${id}`);
        setCategoria({
          tipo: p.tipo,
          sub: p.subtipo,
          secao: p.secao,
          estante: p.estante,
          status: p.status,
        });
        isbnRef.current = p.isbn || '';

        const tFoto1 = p.ProdutoImagems.find((f) => f.ordem === 1)?.Imagem;
        const tFoto2 = p.ProdutoImagems.find((f) => f.ordem === 2)?.Imagem;
        const tFoto3 = p.ProdutoImagems.find((f) => f.ordem === 3)?.Imagem;
        const bFotos = {
          foto1: null,
          foto2: null,
          foto3: null,
        };
        if (tFoto1) {
          bFotos.foto1 = {
            ...tFoto1,
            naoMudou: true,
          };
        }
        if (tFoto2) {
          bFotos.foto2 = {
            ...tFoto2,
            naoMudou: true,
          };
        }
        if (tFoto3) {
          bFotos.foto3 = {
            ...tFoto3,
            naoMudou: true,
          };
        }
        setFoto(bFotos);
        setTimeout(() => {
          formRef.current.setData({
            ...p,
            id_autor: p.Autor
              ? {
                  label: p.Autor.nome,
                  value: p.Autor.id,
                }
              : null,
            id_editora: p.Editora
              ? {
                  label: p.Editora.nome,
                  value: p.Editora.id,
                }
              : null,
          });
          closeLoader();
        }, 200);
      } catch (err) {
        closeLoader();
        toast('Não foi possível carregar os dados do produto');
        history.goBack();
      }
    }
    if (!search?.id) {
      return;
    }

    getProduto(search?.id);
  }, [search?.id]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
            <Card body>
              <>
                <h5 className="mb-3">Informações Básicas</h5>
                <Row className="px-2">
                  <Col lg={3}>
                    <Input
                      label="ISBN"
                      name="isbn"
                      type="number"
                      className="form-control"
                      onBlur={(e) => {
                        const val = e.target.value;
                        if (isbnRef.current !== val) {
                          isbnRef.current = val;
                          if (val) {
                            askVerifyData(val);
                          }
                        }
                      }}
                    />
                  </Col>
                  <Col lg={9}>
                    <Input
                      label="Título"
                      name="titulo"
                      className="form-control"
                    />
                  </Col>

                  {categoria.tipo !== 'Mídia' && (
                    <>
                      <Col lg={4}>
                        <ReactSelectAsyncCreatable
                          label="Autor"
                          name="id_autor"
                          isClearable
                          onCreateOption={(e) => handleCreate(e, 'autor')}
                          loadOptions={async (e) => {
                            const result = await loadOptions({
                              value: e,
                              type: 'autor',
                              url: '/autores',
                            });
                            return result;
                          }}
                        />
                      </Col>
                      <Col lg={4}>
                        <ReactSelectAsyncCreatable
                          label="Editora"
                          name="id_editora"
                          isClearable
                          onCreateOption={(e) => handleCreate(e, 'editora')}
                          loadOptions={async (e) => {
                            const result = await loadOptions({
                              value: e,
                              type: 'editora',
                              url: '/editoras',
                            });
                            return result;
                          }}
                        />
                      </Col>
                    </>
                  )}
                  <Col lg={categoria.tipo === 'Mídia' ? 3 : 4}>
                    <Input
                      label="Edição"
                      name="edicao"
                      className="form-control"
                    />
                  </Col>
                  <Col lg={3}>
                    <Input
                      label="Ano"
                      name="ano"
                      type="number"
                      className="form-control"
                    />
                  </Col>
                  <Col lg={3}>
                    <Select
                      name="tipo"
                      label="Tipo"
                      className="form-control"
                      data={data}
                      onChange={(e) => {
                        setCategoria((old) => ({
                          ...old,
                          tipo: e.target.value,
                        }));
                      }}
                    />
                  </Col>
                  {!!subtipo?.length && (
                    <Col lg={3}>
                      <Select
                        name="subtipo"
                        label="Sub-Tipo"
                        className="form-control"
                        data={selecione.concat(subtipo)}
                        onChange={(e) => {
                          setCategoria((old) => ({
                            ...old,
                            sub: e.target.value,
                          }));
                        }}
                      />
                    </Col>
                  )}
                  {!!secao?.length && (
                    <Col lg={3}>
                      <Select
                        name="secao"
                        label="Seção"
                        className="form-control"
                        data={selecione.concat(secao)}
                        onChange={(e) => {
                          setCategoria((old) => ({
                            ...old,
                            secao: e.target.value,
                          }));
                        }}
                      />
                    </Col>
                  )}
                  {!!estante?.length && (
                    <Col lg={3}>
                      <Select
                        name="estante"
                        label="Estante"
                        className="form-control"
                        data={selecione.concat(estante)}
                      />
                    </Col>
                  )}
                  <Col lg={3}>
                    <Select
                      name="idioma"
                      label="Idioma"
                      data={[
                        {
                          value: '',
                          label: 'Selecione',
                        },
                        {
                          value: 'Português',
                          label: 'Português',
                        },
                        {
                          value: 'Inglês',
                          label: 'Inglês',
                        },
                        {
                          value: 'Espanhol',
                          label: 'Espanhol',
                        },
                        {
                          value: 'Italiano',
                          label: 'Italiano',
                        },
                        {
                          value: 'Japonês',
                          label: 'Japonês',
                        },
                        {
                          value: 'Mandarim',
                          label: 'Mandarim',
                        },
                        {
                          value: 'Francês',
                          label: 'Francês',
                        },
                        {
                          value: 'Russo',
                          label: 'Russo',
                        },
                        {
                          value: 'Árabe',
                          label: 'Árabe',
                        },
                        {
                          value: 'Alemão',
                          label: 'Alemão',
                        },
                        {
                          value: 'Turco',
                          label: 'Turco',
                        },
                        {
                          value: 'Polonês',
                          label: 'Polonês',
                        },
                        {
                          value: 'Holandês',
                          label: 'Holandês',
                        },
                      ]}
                      className="form-control"
                    />
                  </Col>

                  <Col lg={3}>
                    <Select
                      name="condicao"
                      label="Condição"
                      data={[
                        {
                          value: 'Usado',
                          label: 'Usado',
                        },
                        {
                          value: 'Novo',
                          label: 'Novo',
                        },
                      ]}
                      className="form-control"
                    />
                  </Col>
                  {categoria?.tipo === 'Livro' && (
                    <>
                      <Col lg={3} md={12}>
                        <label className="label-form">&nbsp;</label>
                        <CheckBox name="capa_dura" label="Capa dura ?" />
                      </Col>
                      <Col lg={3} md={12}>
                        <label className="label-form">&nbsp;</label>
                        <CheckBox name="box" label="Box ou coleções ?" />
                      </Col>
                      <Col lg={3} md={12}>
                        <label className="label-form">&nbsp;</label>
                        <CheckBox name="bolso" label="Bolso ?" />
                      </Col>
                    </>
                  )}
                </Row>
                <Row>
                  <Col lg={6} md={12}>
                    <TextArea
                      label="Descrição produto"
                      name="descricao"
                      className="form-control"
                    />
                  </Col>
                  <Col lg={6} md={12}>
                    <TextArea
                      label="Descrição condição"
                      name="descricao_estado"
                      className="form-control"
                    />
                  </Col>
                </Row>
              </>
            </Card>
            <Card body>
              <>
                <h5 className="mb-3">Foto do produto</h5>
                <h6 className="txt-muted">
                  Caso a proporção seja diferente de 1:1 ou dimensão seja
                  diferente de 700x700, a imagem será redimensionada.
                </h6>
                <Row className="px-2">
                  <Col lg={4} className="mb-2">
                    <button
                      type="button"
                      className="touchable"
                      onClick={() => {
                        openOffcanvas(1);
                      }}
                    >
                      <div className="btn-img-placeholder">
                        {foto.foto1?.path ? (
                          <img
                            src={foto.foto1.path}
                            alt="Imagem produto"
                            height={300}
                            width="100%"
                            className="object-fit"
                          />
                        ) : (
                          <HiPlus size={30} />
                        )}
                      </div>
                    </button>
                  </Col>
                  <Col lg={4} className="mb-2">
                    <button
                      type="button"
                      className="touchable"
                      onClick={() => {
                        openOffcanvas(2);
                      }}
                    >
                      <div className="btn-img-placeholder">
                        {foto.foto2?.path ? (
                          <img
                            src={foto.foto2.path}
                            alt="Imagem produto"
                            height={300}
                            width="100%"
                            className="object-fit"
                          />
                        ) : (
                          <HiPlus size={30} />
                        )}
                      </div>
                    </button>
                  </Col>
                  <Col lg={4} className="mb-2">
                    <button
                      type="button"
                      className="touchable"
                      onClick={() => {
                        openOffcanvas(3);
                      }}
                    >
                      <div className="btn-img-placeholder">
                        {foto.foto3?.path ? (
                          <img
                            src={foto.foto3.path}
                            alt="Imagem produto"
                            height={300}
                            width="100%"
                            className="object-fit"
                          />
                        ) : (
                          <HiPlus size={30} />
                        )}
                      </div>
                    </button>
                  </Col>
                </Row>
              </>
            </Card>
            <Card body>
              <>
                <h5 className="mb-3">Informações de venda</h5>
                <Row className="px-2">
                  <Col lg={3} md={6}>
                    <NumberFormat
                      name="preco_normal"
                      label="Preço"
                      className="form-control"
                      prefix="R$"
                    />
                  </Col>
                  <Col lg={3} md={6}>
                    <NumberFormat
                      name="preco_desconto"
                      label="Preço com desconto"
                      className="form-control"
                      prefix="R$"
                    />
                  </Col>
                  <Col lg={3} md={6}>
                    <Input
                      name="estoque_minimo"
                      label="Estoque mínimo"
                      type="number"
                      className="form-control"
                    />
                  </Col>
                  <Col lg={3} md={6}>
                    <Input
                      name="estoque_disponivel"
                      label="Estoque"
                      type="number"
                      className="form-control"
                    />
                  </Col>
                  <Col lg={3}>
                    <label className="label-form">&nbsp;</label>
                    <CheckBox
                      name="aceita_proposta"
                      label="Aceita proposta ?"
                    />
                  </Col>
                  <Col lg={3} md={6}>
                    <Input
                      name="taxa_proposta_minima"
                      label="Taxa proposta mínima"
                      type="number"
                      className="form-control"
                    />
                  </Col>
                  {search?.id && categoria.status !== 'Pendente' && (
                    <Col lg={3}>
                      <Select
                        name="status"
                        label="Status"
                        className="form-control"
                        data={selecione.concat(['Ativo', 'Inativo'])}
                      />
                    </Col>
                  )}
                </Row>
              </>
            </Card>
            <Card body>
              <>
                <h5 className="mb-3">Informações de envio</h5>
                <Row>
                  <Col lg={3} md={6}>
                    <Input
                      name="peso"
                      label="Peso"
                      type="number"
                      className="form-control"
                    />
                  </Col>
                  <Col lg={3} md={6}>
                    <Input
                      name="largura"
                      label="Largura"
                      type="number"
                      step="0.01"
                      className="form-control"
                    />
                  </Col>
                  <Col lg={3} md={6}>
                    <Input
                      name="altura"
                      label="Altura"
                      type="number"
                      step="0.01"
                      className="form-control"
                    />
                  </Col>
                  <Col lg={3} md={6}>
                    <Input
                      name="comprimento"
                      label="Comprimento"
                      type="number"
                      step="0.01"
                      className="form-control"
                    />
                  </Col>
                </Row>
              </>
            </Card>
            <Card body hidden>
              <>
                <Row>
                  <Col>
                    <CheckBox
                      name="manter_infos"
                      label="Manter informações ao salvar"
                    />
                  </Col>
                </Row>
              </>
            </Card>

            <Row className="mb-0">
              <Col className="d-flex justify-content-end">
                {/* <Button color="success" type="button" className="mr-3">
                  Salvar e não publicar
                </Button> */}

                <Button color="primary" type="submit">
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <Offcanvas isOpen={!!offcanvas} toggle={toggleOffcanvas}>
        <OffcanvasHeader toggle={toggleOffcanvas}>Foto produto</OffcanvasHeader>
        <OffcanvasBody>
          <Row>
            <Form>
              <Col className="mb-3">
                <Image
                  name="foto"
                  radius={false}
                  btnSelect="Enviar imagem"
                  objectFit="contain"
                  onChange={(e) => {
                    setFoto((old) => ({
                      ...old,
                      [`foto${offcanvas?.ordem}`]: e,
                    }));
                    toggleOffcanvas();
                  }}
                />
              </Col>

              {!foto[`foto${offcanvas?.ordem}`]?.remover && (
                <Col className="mb-3">
                  <Button
                    color="danger"
                    type="button"
                    className="btn-block"
                    onClick={() => {
                      setFoto((old) => ({
                        ...old,
                        [`foto${offcanvas?.ordem}`]: {
                          remover: true,
                        },
                      }));
                      toggleOffcanvas();
                    }}
                  >
                    Remover foto
                  </Button>
                </Col>
              )}
            </Form>

            {offcanvas?.fotos?.data?.length > 0 && (
              <p>Ou selecione uma existente</p>
            )}

            {offcanvas?.fotos?.data?.map((m) => (
              <Col lg={6}>
                <button
                  className="touchable"
                  type="button"
                  onClick={() => {
                    setFoto((old) => ({
                      ...old,
                      [`foto${offcanvas?.ordem}`]: m,
                    }));
                    toggleOffcanvas();
                  }}
                >
                  <img
                    src={m.path}
                    alt="foto ilustrativa produto"
                    width="100%"
                    key={m.id}
                  />
                </button>
              </Col>
            ))}
          </Row>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
}

export default CriarProduto;
