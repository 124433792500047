import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  CardHeader,
  TabPane,
  TabContent,
  Table,
} from 'reactstrap';
import Swal from 'sweetalert2';
import Pagination from 'react-paginate';
import { useValidator } from '../../hooks';
import api from '../../services/api';

function adicionaZero(numero) {
  if (numero <= 9) {
    return `0${numero}`;
  }
  return numero;
}

function formataData(data) {
  const date = new Date(data);

  return `${adicionaZero(date.getDate().toString())}/${adicionaZero(
    date.getMonth() + 1
  ).toString()}/${date.getFullYear()} - ${adicionaZero(
    date.getHours()
  )}:${adicionaZero(date.getMinutes())}`;
}

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function Financeiro() {
  const { showLoader, closeLoader, toast } = useValidator();

  const [activeTab, setActiveTab] = useState('solicitados-parceiros');
  const [dados, setDados] = useState([]);
  const [dadosConcluido, setDadosConcluido] = useState([]);

  async function handleSubmit(tab, params = '') {
    showLoader();
    try {
      const { data } = await api.get(`/resgates-${tab}${params}`);

      if (tab === 'solicitados-parceiros') {
        setDados(data);
      } else {
        setDadosConcluido(data);
      }
    } catch (err) {
      toast(err.mensagem, { type: 'error' });
    }
    closeLoader();
  }

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      handleSubmit(tab);
    }
  };

  async function verChavePix(id) {
    try {
      const { data } = await api.get(`/chave-pix/${id}`);
      Swal.fire({
        title: data,
        showConfirmButton: true,
      });
    } catch (err) {
      toast(err.mensagem, { type: 'error' });
    }
  }

  useEffect(() => {
    handleSubmit('solicitados-parceiros');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleTransferido(ids) {
    const body = {
      ids,
    };

    swalWithBootstrapButtons
      .fire({
        title: 'Deseja marcar como transferido?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: 'Aguarde',
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          api
            .post('/concluir-resgate', body)
            .then(() => {
              Swal.close();
              handleSubmit('solicitados-parceiros');
              toast('Resgate transferido com sucesso!', { type: 'success' });
            })
            .catch(() => {
              toast('Erro ao transferir resgate!', { type: 'error' });
            });
        }
      });
  }

  return (
    <>
      <Card>
        <CardHeader>
          <Nav tabs>
            <NavItem>
              <NavLink
                active={activeTab === 'solicitados-parceiros'}
                onClick={() => {
                  toggle('solicitados-parceiros');
                }}
              >
                Resgates solicitados
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'concluidos-parceiros'}
                onClick={() => {
                  toggle('concluidos-parceiros');
                }}
              >
                Resgates concluídos
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="solicitados-parceiros">
              <Row>
                <Col>
                  <Table className="rwd-table" responsive>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Nome parceiro</th>
                        <th>CPF</th>
                        <th>Total</th>
                        <th>Valor total a transferir</th>
                        <th className="text-center">Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dados?.map((i) => (
                        <tr key={i?.id}>
                          <td data-label="ID">{i?.id_usuario_garagem}</td>
                          <td data-label="Nome parceiro">{i?.nome_parceiro}</td>
                          <td data-label="CPF">{i?.cpf}</td>
                          <td data-label="Total pedido">
                            {Number(i?.total_pedido).toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </td>
                          <td data-label="Valor total a transferir">
                            {Number(i?.total_parceiro).toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </td>
                          <td
                            data-label="Data Hora Pagamento"
                            className="text-center"
                          >
                            <Button
                              color="secondary"
                              type="button"
                              className="mr-2"
                              onClick={() => verChavePix(i?.id_usuario_garagem)}
                            >
                              Chave PIX
                            </Button>
                            <Button
                              color="primary"
                              type="button"
                              onClick={() => handleTransferido(i?.ids_resgate)}
                            >
                              Concluído
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="concluidos-parceiros">
              <Row>
                <Col>
                  <Table className="rwd-table" responsive>
                    <thead>
                      <tr>
                        <th>ID Pagamento</th>
                        <th>ID Usuario</th>
                        <th>Usuario</th>
                        <th>Total</th>
                        <th>Taxa</th>
                        <th>Valor Líquido</th>
                        <th className="text-center">Data Hora Solicitação</th>
                        <th className="text-center">Data Hora Concluído</th>
                        <th className="text-center">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dadosConcluido?.data?.map((i) => (
                        <tr key={i?.id}>
                          <td data-label="ID Pagamento">{i?.id_pagamento}</td>
                          <td data-label="ID Usuario">
                            {i?.id_usuario_garagem}
                          </td>
                          <td data-label="Usuario">{i?.Usuario?.nome}</td>
                          <td data-label="Total pedido">
                            {Number(i?.total).toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </td>
                          <td data-label="Percentual Só Sebos">
                            {i?.percentual_easygarage} %
                          </td>
                          <td data-label="Valor Líquido">
                            {Number(i?.valor_liquido).toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </td>
                          {/* <td
                            data-label="Data Hora Pedido"
                            className="text-center"
                          >
                            {formataData(i?.data_hora_pedido)}
                          </td>
                          <td
                            data-label="Data Hora Pagamento"
                            className="text-center"
                          >
                            {formataData(i?.data_hora_pagamento)}
                          </td> */}
                          <td
                            data-label="Data Hora Solicitação"
                            className="text-center"
                          >
                            {formataData(i?.data_hora_solicitado)}
                          </td>
                          <td
                            data-label="Data Hora Resgate"
                            className="text-center"
                          >
                            {formataData(i?.data_hora_resgate)}
                          </td>
                          <td
                            data-label="Data Hora Pagamento"
                            className="text-center"
                          >
                            {i?.status}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination
                    nextLabel={<FaChevronRight />}
                    previousLabel={<FaChevronLeft />}
                    pageCount={dadosConcluido?.pages || 1}
                    onPageChange={({ selected }) => {
                      if (selected !== 0) {
                        handleSubmit(
                          'concluidos-parceiros',
                          `?page=${selected}`
                        );
                      }
                    }}
                    initialPage={0}
                    containerClassName="pagination justify-end mr-1"
                    activeClassName="active"
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="concluidos">
              <Row>
                <Col>
                  <Table className="rwd-table" responsive>
                    <thead>
                      <tr>
                        <th>ID Pedido</th>
                        <th>Total pedido</th>
                        <th>Percentual Só Sebos</th>
                        <th>Valor Líquido</th>
                        <th className="text-center">Data Hora Pedido</th>
                        <th className="text-center">Data Hora Pagamento</th>
                        <th className="text-center">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dados?.map((i) => (
                        <tr key={i?.id}>
                          <td data-label="ID Pedido">{i?.id_pedido}</td>
                          <td data-label="Total pedido">
                            {Number(i?.total_pedido).toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </td>
                          <td data-label="Percentual Só Sebos">
                            {i?.percentual_mktplace} %
                          </td>
                          <td data-label="Valor Líquido">
                            {Number(i?.valor_liquido).toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </td>
                          <td
                            data-label="Data Hora Pedido"
                            className="text-center"
                          >
                            {formataData(i?.data_hora_pedido)}
                          </td>
                          <td
                            data-label="Data Hora Pagamento"
                            className="text-center"
                          >
                            {formataData(i?.data_hora_pagamento)}
                          </td>
                          <td
                            data-label="Data Hora Pagamento"
                            className="text-center"
                          >
                            {i?.status}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </>
  );
}

export default Financeiro;
