import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

export default function Select({
  name,
  label,
  type,
  data = [{ label: 'Selecione' }],
  className = '',
  ...rest
}) {
  const inputRef = useRef(null);
  const {
    fieldName,
    defaultValue = null,
    registerField,
    error,
  } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => ref.current.value,
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);
  return (
    <div className="form-group">
      <label htmlFor={fieldName} className="form-label">
        {label}
      </label>

      <select
        ref={inputRef}
        defaultValue={defaultValue}
        className={[className, error ? 'error' : ''].join(' ')}
        {...rest}
      >
        {data.map((m) => (
          <option
            value={typeof m === 'object' ? m.value : m}
            key={typeof m === 'object' ? m.value : m}
          >
            {m.label || m.value || m}
          </option>
        ))}
      </select>
      {error && <small className="error">{error}</small>}
    </div>
  );
}
