import React, { useEffect, useRef, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import {
  FaChevronLeft,
  FaChevronRight,
  FaArrowAltCircleDown,
  FaArrowCircleUp,
} from 'react-icons/fa';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
} from 'reactstrap';
import { Form } from '@unform/web';
import ReactJson from 'react-json-view';
import { Input, Select } from '../../components/unform/index';
import api from '../../services/api';
import { useValidator } from '../../hooks';

function RetornoWebhook() {
  const formRef = useRef();
  const { showLoader, closeLoader, toast } = useValidator();

  const [dados, setDados] = useState([]);

  async function getDados() {
    showLoader();
    try {
      const { data } = await api.get('/taxa');

      setDados(data);
    } catch (err) {
      toast('Erro ao buscar dados!', { type: 'error' });
    }
    closeLoader();
  }

  useEffect(() => {
    getDados();
  }, []);

  async function handleSubmit(body) {
    formRef.current.setErrors({});
    showLoader();
    try {
      const errors = {};

      Object.keys(body).forEach((f) => {
        if (!body[f]) {
          errors[f] = 'Obrigatório';
        }
      });

      formRef.current.setErrors(errors);

      if (!Object.keys(errors).length) {
        await api.post('/taxa', body);
        toast('Dados salvos com sucesso!', { type: 'success' });
      }
    } catch (err) {
      toast('Erro ao salvar dados!', { type: 'error' });
    } finally {
      closeLoader();
    }
  }
  return (
    <>
      <Card>
        <Form
          ref={formRef}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <CardBody>
            <Row>
              <Col>
                <Input
                  label="Valor % (Considerar taxa do ML inclusa)"
                  name="valor"
                  className="form-control"
                  type="number"
                  defaultValue={dados?.valor}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="primary" type="submit">
              Enviar
            </Button>
          </CardFooter>
        </Form>
      </Card>
    </>
  );
}

export default RetornoWebhook;
