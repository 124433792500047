/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';
import { FiFilter, FiDatabase, FiSettings } from 'react-icons/fi';
import {
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaRegClock,
  FaMoneyCheckAlt,
} from 'react-icons/fa';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap';
import { Form } from '@unform/web';
import { Input, Select } from '../../components/unform/index';
import api from '../../services/api';
import { useValidator } from '../../hooks';
import InfosComponent from './infosComponent';
import HistoricoComponent from './historicoComponent';
import PagamentosComponent from './pagamentosComponent';

function formatData(data) {
  const dataSplitArray = data?.split('T')[0]?.split('-');
  return `${dataSplitArray[2]}/${dataSplitArray[1]}/${dataSplitArray[0]}`;
}

function Locacoes() {
  const { showLoader, closeLoader, toast } = useValidator();

  const filtrosRef = useRef();

  const [filtro, setFiltro] = useState({});
  const [modal, setModal] = useState({ open: false, tipo: '' });
  const [dados, setDados] = useState([]);

  const [infos, setInfos] = useState(null);
  const [histPag, setHistPag] = useState(null);

  const toggleModal = (tipo) => setModal({ open: !modal?.open, tipo });

  async function getDados(page) {
    showLoader();
    try {
      const params = Object.keys(filtro)
        .map((e) => {
          if (e === 'page') {
            if (Number.isNaN(filtro[e])) {
              return `${e}=${1}`;
            }
          }
          return `${e}=${filtro[e]}`;
        })
        .join('&');

      const { data } = await api.get(
        `/vagaveiculo?page=${page || 1}&${params}`
      );

      setDados(data);
    } catch (err) {
      toast('Erro ao buscar dados!', { type: 'error0' });
    }
    closeLoader();
  }

  useEffect(() => {
    getDados();
  }, [filtro]);

  let timeoutConsulta;
  function filtrarConsulta(e, value) {
    setFiltro({ ...filtro, [value]: e });
  }
  const debounceConsulta = async (body, value) =>
    new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body, value));
      }, 500);
    });

  async function ativaInativa(id, status) {
    showLoader();
    try {
      const body = {
        status: status === 'Ativo' ? 'Inativo' : 'Ativo',
      };

      await api.put(`/editar-parceiros/${id}`, body);
      toast('Ativado/Inativado com sucesso!', { type: 'success' });
      getDados();
    } catch (err) {
      toast('Erro ao ativar/inativar!', { type: 'error' });
    }
    closeLoader();
  }

  async function getDadosLocacao(id) {
    showLoader();
    try {
      const { data } = await api.get(`/dados/vagaveiculo/${id}`);
      setInfos(data);
    } catch (err) {
      toast('Erro ao buscar dados!', { type: 'error' });
    }
    closeLoader();
  }

  return (
    <>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {dados?.count || 0}</h6>
          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter color="rgba(var(--theme-color-rgb), 0.6)" size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
        </div>
        <div className="filter-collapse px-3 pb-3">
          <UncontrolledCollapse toggler="#toggler">
            <Form onSubmit={(data) => console.log(data)} ref={filtrosRef}>
              <Row>
                <Col>
                  <Input
                    label="Data início"
                    className="form-control"
                    placeholder="Digite a data"
                    name="data_contrato_inicio"
                    type="date"
                    onChange={(e) =>
                      debounceConsulta(e.target.value, 'data_contrato_inicio')
                    }
                  />
                </Col>
                <Col>
                  <Input
                    label="Data fim"
                    className="form-control"
                    placeholder="Digite a data"
                    name="data_contrato_fim"
                    type="date"
                    onChange={(e) =>
                      debounceConsulta(e.target.value, 'data_contrato_fim')
                    }
                  />
                </Col>
                <Col>
                  <Select
                    label="Status in loco"
                    className="form-control"
                    name="status_in_loco"
                    onChange={(e) =>
                      debounceConsulta(e.target.value, 'status_in_loco')
                    }
                    data={[
                      {
                        value: '',
                        label: 'Todos',
                      },
                      {
                        value: 'Livre',
                        label: 'Livre',
                      },
                      {
                        value: 'Utilizando',
                        label: 'Utilizando',
                      },
                    ]}
                  />
                </Col>
                <Col>
                  <Select
                    label="Status"
                    className="form-control"
                    name="status"
                    onChange={(e) => debounceConsulta(e.target.value, 'status')}
                    data={[
                      {
                        value: '',
                        label: 'Todos',
                      },
                      {
                        value: 'Ativo',
                        label: 'Ativo',
                      },
                      {
                        value: 'Pendente',
                        label: 'Pendente de pagamento (Renovação)',
                      },
                      {
                        value: 'Reservado',
                        label: 'Reservado (Não pago)',
                      },
                      {
                        value: 'Inativo',
                        label: 'Inativo',
                      },
                      {
                        value: 'Bloqueado',
                        label: 'Bloqueado',
                      },
                    ]}
                  />
                </Col>
                {/* <Col>
                  <Input
                    label="Plano id Zoop"
                    className="form-control"
                    placeholder="Digite o motivo"
                    name="plano_id_zoop"
                    onChange={(e) =>
                      debounceConsulta(e.target.value, 'plano_id_zoop')
                    }
                  />
                </Col> */}
                {/* <Col>
                  <Input
                    label="Assinatura id Zoop"
                    className="form-control"
                    placeholder="Digite o motivo"
                    name="assinatura_id_zoop"
                    onChange={(e) =>
                      debounceConsulta(e.target.value, 'assinatura_id_zoop')
                    }
                  />
                </Col> */}
                <Col>
                  <Input
                    label="Placa veículo"
                    className="form-control"
                    placeholder="Digite o motivo"
                    name="placa_veiculo"
                    onChange={(e) =>
                      debounceConsulta(e.target.value, 'placa_veiculo')
                    }
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>

        <Table className="rwd-table" responsive>
          <thead>
            <tr>
              <th>Data início</th>
              <th>Data fim</th>
              <th>Status in loco</th>
              <th>Valor</th>
              <th>Contrato Frequencia</th>
              <th>Vaga</th>
              {/* <th>Assinatura id zoop</th> */}
              <th className="text-center">Status</th>
              <th className="text-end">Ação</th>
            </tr>
          </thead>
          <tbody>
            {dados?.data?.map((m) => (
              <>
                <tr key={m?.id} style={{ borderTop: '2px solid gray' }}>
                  <td data-label="Data início">
                    {m?.data_contrato_inicio
                      ? formatData(m?.data_contrato_inicio)
                      : m?.status === 'Reservado'
                      ? 'Pendente'
                      : 'Locação ativa'}
                  </td>
                  <td data-label="Data fim">
                    {m?.data_contrato_fim
                      ? formatData(m?.data_contrato_fim)
                      : m?.status === 'Reservado'
                      ? 'Pendente'
                      : 'Locação ativa'}
                  </td>
                  <td data-label="Status in loco">
                    {m?.status_in_loco ? m?.status_in_loco : 'Pendente'}
                  </td>
                  <td data-label="Mensalidade">
                    {Number(m?.GaragemVaga?.valor_mensalidade).toLocaleString(
                      'pt-BR',
                      {
                        style: 'currency',
                        currency: 'BRL',
                      }
                    )}
                  </td>
                  <td data-label="Contrato Frequencia">
                    {m?.GaragemVaga?.Contrato?.frequencia} -{' '}
                    {m?.GaragemVaga?.Contrato?.intervalo}
                  </td>
                  <td data-label="Vaga">
                    {m?.GaragemVaga?.identificador_vaga}
                  </td>
                  {/* <td data-label="Assinatura id zoop">
                    {m?.assinatura_id_zoop}
                  </td> */}
                  <td data-label="Status" className="text-center">
                    {m?.status}
                  </td>
                  <td data-label="Action" className="text-end">
                    <div className="d-flex justify-content-lg-end justify-content-sm-between">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="link"
                          className="text-decoration-none padding-0-5 d-flex"
                        >
                          <FiSettings className="mr-2" /> <FaChevronDown />
                        </DropdownToggle>
                        <DropdownMenu end>
                          {/* <DropdownItem
                          onClick={() => ativaInativa(m?.id, m?.status)}
                        >
                          <FiToggleLeft className="mr-1" />
                          Inativar/Ativar
                        </DropdownItem> */}
                          <DropdownItem
                            onClick={() => {
                              toggleModal('D');
                              getDadosLocacao(m?.id);
                            }}
                          >
                            <FiDatabase className="mr-1" />
                            Dados
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              toggleModal('H');
                              setHistPag(m?.id);
                            }}
                          >
                            <FaRegClock className="mr-1" />
                            Histórico
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              toggleModal('P');
                              setHistPag(m?.id);
                            }}
                          >
                            <FaMoneyCheckAlt className="mr-1" />
                            Pagamentos
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </td>
                </tr>
                <tr key={`${m?.id}_infos_gerais`}>
                  <td colSpan="9">
                    <Row>
                      <Col lg={3}>
                        <div style={{ display: 'flex' }}>
                          <div className="mr-1">
                            <strong>Alugado para: </strong>
                            {m?.Usuario?.nome}
                          </div>
                        </div>
                      </Col>
                      <Col lg={2}>
                        <div style={{ display: 'flex' }}>
                          <div className="mr-1">
                            <strong>CPF: </strong>
                            {m?.Usuario?.cpf}
                          </div>
                        </div>
                      </Col>
                      <Col lg={2}>
                        <div style={{ display: 'flex' }}>
                          <div className="mr-1">
                            <strong>Telefone: </strong>
                            {m?.Usuario?.telefone}
                          </div>
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div style={{ display: 'flex' }}>
                          <div className="mr-1">
                            <strong>E-mail: </strong>
                            {m?.Usuario?.email}
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div style={{ display: 'flex' }}>
                          <div className="mr-1">
                            <strong>Validade: </strong>
                            {m?.chave
                              ? `${new Date(Number(m?.chave)).toLocaleString()}`
                              : 'N/A'}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados?.pages || 1}
          onPageChange={({ selected }) => getDados(selected + 1)}
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>

      <Modal
        isOpen={modal?.open}
        toggle={() => {
          toggleModal();
          setInfos(null);
          setHistPag(null);
        }}
        size="xl"
      >
        <ModalHeader
          toggle={() => {
            toggleModal();
            setInfos(null);
            setHistPag(null);
          }}
        >
          {modal?.tipo === 'D' && 'Dados'}
          {modal?.tipo === 'H' && 'Histórico'}
          {modal?.tipo === 'P' && 'Pagamentos'}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              {modal?.tipo === 'D' && <InfosComponent data={infos} />}
              {modal?.tipo === 'H' && <HistoricoComponent id={histPag} />}
              {modal?.tipo === 'P' && <PagamentosComponent id={histPag} />}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}

export default Locacoes;
