/* eslint-disable no-return-await */
import React, { useState, useRef } from 'react';
import { Form } from '@unform/web';

import { Button, Card, Col, Row, CardBody, CardFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { Input, ReactSelectAsync, Select } from '../../components/unform/index';
import api from '../../services/api';

function Notifications() {
  const formRef = useRef();

  const [todosUsuarios, setTodosUsuarios] = useState('S');

  async function filtrarConsultaAsync(inputValue) {
    const { data } = await api.get(`/get-usuario?nome=${inputValue}`);

    return data.map((e) => ({
      id: String(e.id),
      value: String(e.id),
      label: e.nome,
    }));
  }

  let selectOptions;
  const optionsUsuarios = async (inputValue) =>
    await new Promise((resolve) => {
      clearTimeout(selectOptions);

      selectOptions = setTimeout(() => {
        resolve(filtrarConsultaAsync(inputValue));
      }, 500);
    });

  async function handleSend(data) {
    const errors = {};

    Object.keys(data).forEach((f) => {
      if (!data[f]) {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      try {
        await api.post('/notificacoes', data);
        toast.success('Notificação enviada com sucesso!');
        formRef.current.setFieldValue('titulo', '');
        formRef.current.setFieldValue('conteudo', '');
        formRef.current.setFieldValue('usuario', 'S');
        setTodosUsuarios('S');
        Swal.close();
      } catch (err) {
        toast.error('Erro ao enviar notificação!');
        Swal.close();
      }
    }
  }

  return (
    <>
      <Card>
        <Form ref={formRef} onSubmit={(data) => handleSend(data)}>
          <CardBody>
            <Row>
              <Col lg={2}>
                <Select
                  name="usuario"
                  label="Enviar para todos os usuários?"
                  className="form-control"
                  onChange={(e) => setTodosUsuarios(e?.target?.value)}
                  data={[
                    { key: 'S', id: 'S', label: 'Sim', value: 'S' },
                    { key: 'N', id: 'N', label: 'Não', value: 'N' },
                  ]}
                />
              </Col>
              {todosUsuarios === 'N' && (
                <Col lg={10}>
                  <ReactSelectAsync
                    label="Usuários"
                    name="ids"
                    isClearable
                    isMulti
                    loadOptions={optionsUsuarios}
                  />
                </Col>
              )}
            </Row>
            <hr />
            <Row>
              <Col lg={12}>
                <Input
                  name="titulo"
                  label="Título"
                  className="form-control"
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Input
                  name="conteudo"
                  label="Conteúdo"
                  className="form-control"
                  type="text"
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="success" type="submit">
              Enviar
            </Button>
          </CardFooter>
        </Form>
      </Card>
    </>
  );
}

export default Notifications;
