/* eslint-disable camelcase */
import React, { useState } from 'react';
import {
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

function formatarDataHora(d) {
  const data = new Date(d);
  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0');
  const ano = String(data.getFullYear()).padStart(4, '0');
  const horas = String(data.getHours()).padStart(2, '0');
  const minutos = String(data.getMinutes()).padStart(2, '0');

  return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
}

const InfosComponent = ({ data }) => {
  const [activeTab, setActiveTab] = useState('contrato');

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  if (!data) {
    return <p>Nenhum dado disponível.</p>;
  }

  const {
    id,
    data_contrato_inicio,
    data_contrato_fim,
    status_in_loco,
    status,
    createdAt,
    updatedAt,
    id_garagem_vaga,
    GaragemVaga,
    Usuario,
  } = data;

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === 'contrato' ? 'active' : ''}
            onClick={() => toggleTab('contrato')}
          >
            Contrato
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === 'garagem' ? 'active' : ''}
            onClick={() => toggleTab('garagem')}
          >
            Garagem e Vaga
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === 'veiculo' ? 'active' : ''}
            onClick={() => toggleTab('veiculo')}
          >
            Usuário Veículo
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="contrato">
          <Row>
            <Col>
              <h5>Dados do Contrato:</h5>
              <hr />
              <p>
                <strong>ID do Contrato:</strong> {id}
              </p>
              <p>
                <strong>Data de Início do Contrato:</strong>{' '}
                {formatarDataHora(data_contrato_inicio)}
              </p>
              <p>
                <strong>Data de Término do Contrato:</strong>{' '}
                {data_contrato_fim
                  ? formatarDataHora(data_contrato_fim)
                  : 'Contrato vigente'}
              </p>
              <p>
                <strong>Status In Loco:</strong> {status_in_loco || 'N/A'}
              </p>
              <p>
                <strong>Status:</strong> {status}
              </p>
              {/* <p>
                <strong>ID do Plano Zoop:</strong> {plano_id_zoop || 'N/A'}
              </p>
              <p>
                <strong>ID da Assinatura Zoop:</strong> {assinatura_id_zoop}
              </p> */}
              <p>
                <strong>Data de Criação do Contrato:</strong>{' '}
                {formatarDataHora(createdAt)}
              </p>
              <p>
                <strong>Data de Atualização do Contrato:</strong>{' '}
                {formatarDataHora(updatedAt)}
              </p>
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="garagem">
          <Row>
            <Col>
              <h5>Dados da Vaga:</h5>
              <hr />
              <p>
                <strong>ID da Garagem Vaga:</strong> {id_garagem_vaga}
              </p>
              {GaragemVaga ? (
                <div>
                  <p>
                    <strong>Valor da Mensalidade:</strong>{' '}
                    {GaragemVaga?.valor_mensalidade}
                  </p>
                  <p>
                    <strong>Identificador da Vaga:</strong>{' '}
                    {GaragemVaga?.identificador_vaga}
                  </p>
                  <p>
                    <strong>Categoria Tamanho Máximo:</strong>{' '}
                    {GaragemVaga?.categoria_tamanho_max}
                  </p>
                  <p>
                    <strong>Aceita Moto:</strong>{' '}
                    {GaragemVaga?.aceita_moto ? 'Sim' : 'Não'}
                  </p>
                  <p>
                    <strong>Vaga Coberta:</strong>{' '}
                    {GaragemVaga?.vaga_coberta ? 'Sim' : 'Não'}
                  </p>
                  <p>
                    <strong>Vaga Especial:</strong>{' '}
                    {GaragemVaga?.vaga_especial ? 'Sim' : 'Não'}
                  </p>
                  <p>
                    <strong>Portão Eletrônico:</strong>{' '}
                    {GaragemVaga?.portao_eletronico ? 'Sim' : 'Não'}
                  </p>
                  <p>
                    <strong>Status da Garagem:</strong> {GaragemVaga?.status}
                  </p>
                  <p>
                    <strong>Data de Criação da Garagem Vaga:</strong>{' '}
                    {formatarDataHora(GaragemVaga?.createdAt)}
                  </p>
                  <p>
                    <strong>Data de Atualização da Garagem Vaga:</strong>{' '}
                    {formatarDataHora(GaragemVaga?.updatedAt)}
                  </p>

                  {GaragemVaga?.Garagem ? (
                    <div>
                      <hr />
                      <h5>Dados da Garagem:</h5>
                      <hr />
                      <p>
                        <strong>ID da Garagem:</strong>{' '}
                        {GaragemVaga?.Garagem?.id}
                      </p>
                      <p>
                        <strong>CEP:</strong> {GaragemVaga?.Garagem?.cep}
                      </p>
                      <p>
                        <strong>Logradouro:</strong>{' '}
                        {GaragemVaga?.Garagem?.logradouro}
                      </p>
                      <p>
                        <strong>Número:</strong> {GaragemVaga?.Garagem?.numero}
                      </p>
                      <p>
                        <strong>Complemento:</strong>{' '}
                        {GaragemVaga?.Garagem?.complemento}
                      </p>
                      <p>
                        <strong>Bairro:</strong> {GaragemVaga?.Garagem?.bairro}
                      </p>
                      <p>
                        <strong>Cidade:</strong> {GaragemVaga?.Garagem?.cidade}
                      </p>
                      <p>
                        <strong>Estado:</strong> {GaragemVaga?.Garagem?.estado}
                      </p>
                      <p>
                        <strong>País:</strong> {GaragemVaga?.Garagem?.pais}
                      </p>
                      <p>
                        <strong>Latitude:</strong>{' '}
                        {GaragemVaga?.Garagem?.latitude}
                      </p>
                      <p>
                        <strong>Longitude:</strong>{' '}
                        {GaragemVaga?.Garagem?.longitude}
                      </p>

                      {GaragemVaga?.Garagem?.Usuario ? (
                        <div>
                          <hr />
                          <h5>Dados do Usuário da Garagem:</h5>
                          <hr />
                          <p>
                            <strong>ID do Usuário:</strong>{' '}
                            {GaragemVaga?.Garagem?.Usuario?.id}
                          </p>
                          <p>
                            <strong>Nome:</strong>{' '}
                            {GaragemVaga?.Garagem?.Usuario?.nome}
                          </p>
                          <p>
                            <strong>CPF:</strong>{' '}
                            {GaragemVaga?.Garagem?.Usuario?.cpf}
                          </p>
                          <p>
                            <strong>Email:</strong>{' '}
                            {GaragemVaga?.Garagem?.Usuario?.email}
                          </p>
                          <p>
                            <strong>Telefone:</strong>{' '}
                            {GaragemVaga?.Garagem?.Usuario?.telefone}
                          </p>
                        </div>
                      ) : (
                        <p>Nenhum usuário associado à garagem.</p>
                      )}
                    </div>
                  ) : (
                    <p>Nenhuma garagem associada à vaga.</p>
                  )}
                </div>
              ) : (
                <p>Nenhum dado disponível para a garagem e vaga.</p>
              )}
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="veiculo">
          <Row>
            <Col>
              <h5>Dados do usuário:</h5>
              <hr />
              {Usuario ? (
                <>
                  <p>
                    <strong>ID:</strong> {Usuario?.id}
                  </p>
                  <div>
                    <p>
                      <strong>Nome:</strong> {Usuario?.nome}
                    </p>
                    <p>
                      <strong>CPF:</strong> {Usuario?.cpf}
                    </p>
                    <p>
                      <strong>Telefone:</strong> {Usuario?.telefone}
                    </p>
                    <p>
                      <strong>Email:</strong> {Usuario?.email}
                    </p>
                    <p>
                      <strong>Última visita :</strong>{' '}
                      {formatarDataHora(Usuario?.ultima_visita)}
                    </p>
                  </div>
                </>
              ) : (
                <p>Nenhum dado disponível para o usuário.</p>
              )}
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default InfosComponent;
