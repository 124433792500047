/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useEffect, useRef, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import {
  FaChevronLeft,
  FaChevronRight,
  FaClipboard,
  FaCopy,
} from 'react-icons/fa';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledCollapse,
} from 'reactstrap';
import { Form } from '@unform/web';
import Swal from 'sweetalert2';
import { Input } from '../../components/unform/index';
import api from '../../services/api';
import useValidator from '../../hooks/useValidator';
import getQuery from '../../utils/getQuery';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  reverseButtons: true,
  buttonsStyling: false,
});

function ListarImagens() {
  const [dados, setDados] = useState({ page: 1, data: [], count: 0, pages: 1 });
  const filtrosRef = useRef();
  const debounce = useRef();
  const { showLoader, closeLoader, toast } = useValidator();
  const [modal, setModal] = useState();

  const toggleModal = () => setModal(false);

  function filtrarPesquisa() {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }

  function copy(text) {
    navigator.clipboard.writeText(text);
  }

  async function deleteFoto() {
    showLoader();
    try {
      await api.delete(`/imagens/${modal?.id}`);
      const query = getQuery(filtrosRef.current.getData());
      const { data } = await api.get(
        `/imagens?page=${dados?.page || 1}&${query}`
      );
      setDados((old) => ({
        ...old,
        ...data,
      }));
      toast('Foto excluída com sucesso', { type: 'success' });
      toggleModal();
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  }

  async function askDelete() {
    swalWithBootstrapButtons
      .fire({
        title: 'Confirma a operação ?',
        text: 'Após excluir esta foto não será possível revertar a exclusão',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          deleteFoto();
        }
      });
  }

  async function loadMore(page) {
    const query = getQuery(filtrosRef.current.getData());
    try {
      showLoader();
      const { data } = await api.get(`/imagens?page=${page || 1}&${query}`);
      setDados({
        ...data,
        page: page || 1,
      });
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  }

  async function openModal(m) {
    showLoader();
    try {
      const { data } = await api.get(`/imagens/vagas/${m?.id}`);
      setModal({
        ...m,
        itens: data,
      });
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  }

  useEffect(() => {
    async function getDados() {
      try {
        const { data } = await api.get('/imagens');
        setDados({
          ...data,
          page: 1,
        });
      } catch (err) {
        console.log(err);
      }
    }
    getDados();
  }, []);

  return (
    <>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {dados?.count || 0}</h6>
          <Button
            color="light-primary"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <div className="pl-3 pr-5" />
        </div>
        <div className="filter-collapse px-3">
          <UncontrolledCollapse toggler="#toggler">
            <Form onSubmit={() => loadMore()} ref={filtrosRef}>
              <Row>
                <Col>
                  <Input
                    className="form-control"
                    placeholder="Título"
                    name="titulo"
                    onChange={filtrarPesquisa}
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>

        <Row>
          {dados?.data?.map((m) => (
            <Col lg={2} md={3} sm={4}>
              <button
                className="card btn-none w-100 mb-3"
                type="button"
                onClick={() => openModal(m)}
              >
                <div className="px-3 no-wrap mb-1 mt-1">
                  <small>{m.nome}</small>
                </div>
                <img
                  src={m.path}
                  alt={m.nome}
                  width="100%"
                  className="img-1x1"
                />
              </button>
            </Col>
          ))}
        </Row>

        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados?.pages || 1}
          onPageChange={({ selected }) => loadMore(selected + 1)}
          forcePage={Number(dados?.page) - 1}
          containerClassName="pagination justify-end mr-1 li-lg"
          activeClassName="active"
        />
      </Card>
      <Modal isOpen={!!modal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>
          {modal?.VagaImagem?.GaragemVaga?.Garagem?.Usuario?.nome} -{' '}
          {modal?.VagaImagem?.GaragemVaga?.Garagem?.Usuario?.telefone}
        </ModalHeader>
        <ModalBody>
          <Row className="justify-content-center">
            <Col lg={8}>
              <a href={modal?.path} target="_blank" rel="noreferrer">
                <img
                  src={modal?.path}
                  alt={modal?.nome}
                  width="100%"
                  className="img-1x1"
                />
              </a>
            </Col>
          </Row>
          <h5>Vagas vinculadas</h5>
          <Row>
            {(modal?.itens || []).map((m) => (
              <Col lg={12} className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn-none no-wrap w-100 text-start"
                  onClick={() => copy(m?.id)}
                >
                  <p className="mb-1 text-start">
                    <strong>Id: </strong>
                    <br />
                    {m?.id}
                  </p>
                </button>
                <button
                  type="button"
                  className="btn-none no-wrap w-100 text-start"
                  onClick={() => copy(m?.id_garagem_vaga)}
                >
                  <p className="mb-1 text-start">
                    <strong>Id Garagem vaga: </strong>
                    <br />
                    {m?.id_garagem_vaga}
                  </p>
                </button>
              </Col>
            ))}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="light-danger" onClick={askDelete}>
            Deletar foto
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ListarImagens;
