import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import {
  HiChevronDown,
  HiDotsHorizontal,
  HiOutlinePencil,
  HiPencil,
  HiViewGrid,
  HiViewList,
} from 'react-icons/hi';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useHistory } from 'react-router-dom';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroup,
  InputGroupText,
  Nav,
  NavLink,
  NavItem,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  TabContent,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import Swal from 'sweetalert2';
import BouncingDotsLoader from '../../components/boucingDots';
import {
  CheckBox,
  Input,
  Select,
  ReactSelect,
  NumberFormat,
} from '../../components/unform';
import { useValidator } from '../../hooks';
import api from '../../services/api';
import formFiltros from '../../utils/formFiltros';
import semFoto from '../../assets/images/sem-foto.jpg';

const Grid = ({ dados = [], gridType, toggle }) => {
  if (gridType === 'card') {
    return (
      <>
        {dados?.map((m) => {
          const disc =
            m?.preco_desconto &&
            (
              100 -
              (Number(m?.preco_desconto) / Number(m?.preco_normal)) * 100
            ).toFixed(0);

          return (
            <Col lg={3} md={4} sm={6} key={m?.id} className="mb-3">
              <Card className="mb-0 h-100">
                <CardHeader
                  className="pt-2 text-center"
                  style={{
                    border: '1px solid rgba(var(--theme-color-rgb), 0.12)',
                  }}
                >
                  <strong className="small">
                    {m?.Parceiro?.nome_fantasia}
                  </strong>
                </CardHeader>
                {m?.status !== 'Ativo' && (
                  <div
                    style={{
                      position: 'absolute',
                      left: 0,
                    }}
                  >
                    <Badge color="secondary">{m?.status}</Badge>
                  </div>
                )}
                {m?.status === 'Ativo' && m?.estoque_disponivel <= 0 && (
                  <div
                    style={{
                      position: 'absolute',
                      left: 0,
                    }}
                  >
                    <Badge color="warning">Esgotado</Badge>
                  </div>
                )}
                {Number(disc) > 0 && (
                  <div
                    style={{
                      position: 'absolute',
                      right: 0,
                    }}
                  >
                    <Badge color="danger">{disc}% OFF</Badge>
                  </div>
                )}
                <img
                  height={250}
                  width="100%"
                  className="object-fit bg-gray1 min-h-250"
                  src={m?.ProdutoImagems[0]?.Imagem?.path || semFoto}
                  alt={m?.titulo}
                />
                <div className="card-product h-100">
                  <div className="txt-dots">
                    <h6 className="txt-dots mb-0">{m?.titulo}</h6>
                    <h6 className="txt-dots small">{m?.Autor?.nome || ''}</h6>
                  </div>
                  <div className="d-flex align-self-end">
                    <div className="d-flex-wrap justify-content-between w-100">
                      <span className="text-red bold">
                        {Number(
                          Number(m?.preco_desconto) > 0
                            ? m?.preco_desconto
                            : m?.preco_normal
                        ).toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </span>
                      <div className="txt-muted">
                        {m?.vendas} Venda{Number(m?.vendas) > 1 ? 's' : ''}
                      </div>
                      <span className="txt-muted">
                        Estoque {m?.estoque_disponivel}
                      </span>
                    </div>
                  </div>

                  {/* <div className="d-flex-wrap justify-between mt-1">
                    <div className="txt-muted">
                      <HiOutlineEye /> 42
                    </div>
                    <div className="txt-muted">
                      <HiOutlineHeart /> 1
                    </div>
                    <div className="txt-muted">Vendas {m.vendas}</div>
                  </div> */}
                </div>
                <div className="hr" />

                <div className="d-flex justify-between mb-1">
                  <Button
                    color="link decoration-none"
                    onClick={(e) => {
                      const direction =
                        window.innerWidth / 2 > e.nativeEvent.clientX
                          ? 'start'
                          : 'end';

                      toggle({
                        ...m,
                        direction,
                      });
                    }}
                    block
                  >
                    Editar <HiPencil />
                  </Button>
                  {/* <div className="hr-vertical" />
                <UncontrolledDropdown direction="down" className="w-100">
                  <DropdownToggle
                    tag={(props) => <Button {...props} color="link" block />}
                  >
                    <HiDotsHorizontal />
                  </DropdownToggle>
                  <DropdownMenu className="w-100">
                    <DropdownItem>Inativar</DropdownItem>
                    <DropdownItem>Perfil</DropdownItem>
                    <DropdownItem>Perfil</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
                </div>
              </Card>
            </Col>
          );
        })}
      </>
    );
  }

  return (
    <Table className="rwd-table" responsive>
      <thead>
        <tr>
          <th>Título</th>
          <th className="w-15">ISBN</th>
          <th className="w-10">Preço</th>
          <th className="w-10">Estoque</th>
          <th className="w-10">Vendas</th>
          <th className="w-10">Status</th>
          <th className="w-10">Parceiro</th>
          <th className="text-end w-10">Opções</th>
        </tr>
      </thead>
      <tbody>
        {dados.map((m) => (
          <tr key={m.id}>
            <td data-label="Título">
              <div className="d-flex align-items-center">
                <img
                  width={60}
                  height={70}
                  className="object-fit bg-gray1"
                  src={m?.ProdutoImagems[0]?.Imagem?.path || semFoto}
                  alt={m.titulo}
                />
                <div className="ml-3">
                  <p className="bold mb-0">{m.titulo}</p>
                  <p className="mb-0">{m?.Autor?.nome}</p>
                </div>
              </div>
            </td>
            <td data-label="ISBN" className="w-15">
              {m.isbn}
            </td>
            <td data-label="Preço" className="w-10">
              <p
                className={`mb-0 ${
                  Number(m.preco_desconto) > 0
                    ? 'line-through text-muted small'
                    : ''
                }`}
              >
                {Number(m.preco_normal).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </p>
              {Number(m.preco_desconto) > 0 && (
                <p className="mb-0">
                  {Number(m.preco_desconto).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </p>
              )}
            </td>
            <td data-label="Estoque" className="w-10">
              {m.estoque_disponivel}
            </td>
            <td data-label="Vendas" className="w-10">
              {m.vendas}
            </td>
            <td data-label="Status" className="w-10">
              {m.status}
            </td>
            <td data-label="Parceiro" className="w-10">
              {m?.Parceiro?.nome_fantasia}
            </td>
            <td data-label="Opções" className="w-10">
              <UncontrolledDropdown className="float-md-right">
                <DropdownToggle
                  color="link"
                  className="text-decoration-none padding-0-5"
                >
                  <HiDotsHorizontal />
                </DropdownToggle>
                <DropdownMenu direction="down">
                  <DropdownItem onClick={() => toggle(m)}>
                    <HiOutlinePencil className="mr-1" />
                    Editar
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function Produtos() {
  const [activeTab, setActiveTab] = useState('Todos');
  const [offcanvas, setOffcanvas] = useState(false);
  const [offcanvasMassa, setOffcanvasMassa] = useState({ show: false });
  const [dados, setDados] = useState({});
  const [gridType, setGridType] = useState(localStorage.gridType || 'card');
  const { showLoader, closeLoader } = useValidator();
  const offcanvasRef = useRef([]);
  const debounceProduto = useRef();
  const history = useHistory();

  const loadMore = async (page) => {
    const nextPage = (page === 0 ? page : dados?.page || 1) + 1;

    const filtros = `page=${nextPage}&${dados?.filtros?.join?.('&') || ''}`;

    const { data } = await api.get(`/produtos?${filtros}`).catch((err) => {
      toast.error(err.mensagem);
      return null;
    });
    if (nextPage === 1) {
      setDados(data);
    } else {
      setDados((old) => ({
        ...old,
        page: nextPage,
        data: (old?.data || []).concat(data?.data || []),
      }));
    }
  };

  const toggleOffcanvas = (id) => {
    history.push(`/produtos/novo?id=${id.id}`);
  };

  const changeGrid = (tipo) => {
    setGridType(tipo);

    localStorage.setItem('gridType', tipo);
  };

  async function handleSubmit(tab) {
    const body = formFiltros();

    const active = tab || activeTab;

    const filtros = Object.entries(body)
      .map(([key, value]) => (value ? `${key}=${value || ''}` : ''))
      .filter(Boolean);

    if (active !== 'Todos') {
      if (active === 'Esgotado') {
        filtros.push('estoque_disponivel=0');
      } else {
        filtros.push(`status=${active}`);
      }
    }

    try {
      showLoader();
      const { data } = await api.get(`/produtos?${filtros.join('&')}`);
      setDados({
        ...data,
        filtros,
      });
    } catch (err) {
      toast.error(err.mensagem);
    }
    closeLoader();
  }

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      handleSubmit(tab);
    }
  };

  async function handleEdit(e) {
    showLoader();
    try {
      await api.put(`/produtos/${offcanvas?.id}`, e);
      setDados({
        ...dados,
        data: dados?.data?.map((m) => {
          if (m.id !== offcanvas?.id) {
            return m;
          }

          return {
            ...m,
            ...e,
          };
        }),
      });
      toggleOffcanvas();
    } catch (err) {
      offcanvasRef.current[0].setErrors(err.errors);
      toast.error(err.mensagem);
    }
    closeLoader();
  }

  async function handleBulkEdit(e) {
    showLoader();
    try {
      const { data } = await api.post('/produtos/massa', {
        ...e,
        tipo: offcanvasMassa.tipo,
      });
      setOffcanvasMassa((old) => ({ show: false, options: old.options }));
      if (data.count > 1) {
        toast.success(`${data.count} produtos foram alterados `);
      } else if (data.count === 1) {
        toast.success(`${data.count} produto foi alterado`);
      } else {
        toast.success('Nenhum produto foi alterado');
      }
      if (data.count >= 0) {
        loadMore(0);
      }
    } catch (err) {
      offcanvasRef.current[1].setErrors(err.errors);
      toast.error(err.mensagem);
    }
    closeLoader();
  }

  async function showOffCanvasMassa(tipo) {
    try {
      if (!offcanvasMassa?.options?.length) {
        showLoader();
        const { data } = await api.get('/options/categorias');
        setOffcanvasMassa({
          show: true,
          tipo,
          options: data
            .find((f) => f.value === 'Livro')
            ?.estante?.map((m) => ({ label: m, value: m })),
        });
        closeLoader();
      } else {
        setOffcanvasMassa((old) => ({
          show: true,
          tipo,
          options: old.options,
        }));
      }
    } catch (err) {
      toast.error(err);
    }
  }

  // async function gerarRelatorio() {
  //   showLoader();
  //   try {
  //     const { data } = await api.post('/relatorio-produtos');

  //     const worksheet = utils.json_to_sheet(data);
  //     const workbook = utils.book_new();
  //     utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  //     const outputFilename = `relatorio_${Date.now()}.xls`;
  //     writeFile(workbook, outputFilename);
  //   } catch (err) {
  //     toast.error(err);
  //   }
  //   closeLoader();
  // }

  // async function swalFile() {
  //   const { value: file } = await Swal.fire({
  //     title: 'Importar produtos',
  //     input: 'file',
  //     inputAttributes: {
  //       accept:
  //         'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  //       'aria-label': 'Adicione seu arquivo de produtos',
  //     },
  //   });

  //   if (file) {
  //     console.log(file);
  //     const reader = new FileReader();
  //     reader.onload = async (e) => {
  //       const data = e.target.result;
  //       const workbook = read(data, { type: 'array' });
  //       const sheetName = workbook.SheetNames[0];
  //       const worksheet = workbook.Sheets[sheetName];
  //       const json = utils.sheet_to_json(worksheet);
  //       Swal.close();
  //       Swal.fire({
  //         title: 'Aguarde',
  //         allowOutsideClick: false,
  //         showConfirmButton: false,
  //         didOpen: () => {
  //           Swal.showLoading();
  //         },
  //       });
  //       try {
  //         const { data: result } = await api.post(
  //           '/produtos/massa/importar',
  //           json
  //         );

  //         toast.success(
  //           `${result.criados}
  // produto(s) importado(s), ${result.comErro.length} produto(s) com erro`
  //         );

  //         if (result.comErro.length) {
  //           swalWithBootstrapButtons
  //             .fire({
  //               title: 'Deseja baixar os produtos com erro ?',
  //               text: `${result.comErro.length} produto(s) com erro`,
  //               icon: 'success',
  //               showCancelButton: true,
  //               confirmButtonText: 'Sim',
  //               cancelButtonText: 'Não',
  //               reverseButtons: true,
  //             })
  //             .then(({ value }) => {
  //               if (value) {
  //                 const worksheet1 = utils.json_to_sheet(
  //                   result.comErro.map((m) => m.produto)
  //                 );
  //                 const workbook1 = utils.book_new();
  //                 utils.book_append_sheet(workbook1, worksheet1, 'Sheet1');

  //                 const outputFilename = 'importacao_erro.xls';
  //                 writeFile(workbook, outputFilename);
  //               }
  //             });
  //         }
  //       } catch (err) {
  //         toast.error(err.mensagem);
  //       }
  //       Swal.close();
  //     };
  //     reader.readAsArrayBuffer(file);
  //   }
  // }

  useEffect(() => {
    const initialData = async () => {
      const { data } = await api.get('/produtos').catch((err) => {
        toast.error(err.mensagem);
        return null;
      });
      setDados(data);
    };
    clearTimeout(debounceProduto.current);
    debounceProduto.current = setTimeout(() => {
      initialData();
    }, 100);
  }, []);

  return (
    <>
      <Card>
        <CardHeader>
          <Nav tabs>
            <NavItem>
              <NavLink
                active={activeTab === 'Todos'}
                onClick={() => {
                  toggle('Todos');
                }}
              >
                Todos
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'Ativo'}
                onClick={() => {
                  toggle('Ativo');
                }}
              >
                Ativo
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'Esgotado'}
                onClick={() => {
                  toggle('Esgotado');
                }}
              >
                Esgotado
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'Inativo'}
                onClick={() => {
                  toggle('Inativo');
                }}
              >
                Inativo
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'Violação'}
                onClick={() => {
                  toggle('Violação');
                }}
              >
                Violação
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Row>
              <Col lg={9}>
                <Row>
                  <Col lg={6} className="mb-3">
                    <InputGroup className="custom">
                      <InputGroupText>
                        <select name="consulta">
                          <option value="titulo">Nome produto</option>
                          <option value="autor">Nome autor</option>
                          <option value="isbn">IBSN</option>
                          <option value="parceiro">Nome parceiro</option>
                        </select>
                      </InputGroupText>
                      <input placeholder="Digite" name="consulta_valor" />
                    </InputGroup>
                  </Col>
                  <Col lg={6} className="mb-3">
                    <InputGroup className="custom">
                      <InputGroupText>
                        <div>Categoria</div>
                      </InputGroupText>
                      <select name="tipo">
                        <option value="">Selecione</option>
                        <option>Livro</option>
                        <option>Mídia</option>
                        <option>Arte</option>
                        <option>Decoração</option>
                        <option>Variedade</option>
                        <option>Colecionáveis</option>
                      </select>
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} className="mb-3">
                    <InputGroup className="custom">
                      <InputGroupText>
                        <div>Estoque</div>
                      </InputGroupText>
                      <input placeholder="De" name="estoque_min" />
                      <div className="overflow-visible">|</div>
                      <input placeholder="Até" name="estoque_max" />
                    </InputGroup>
                  </Col>
                  <Col lg={6} className="mb-3">
                    <InputGroup className="custom">
                      <InputGroupText>
                        <div>Parceiro</div>
                      </InputGroupText>
                      <input placeholder="ID" name="id_parceiro" />
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
              <Col lg={3}>
                <Row>
                  <Col xs={6} className="mb-1">
                    <Button
                      color="light-danger"
                      type="reset"
                      onClick={() => handleSubmit()}
                      block
                    >
                      Redefinir
                    </Button>
                  </Col>
                  <Col xs={6} className="mb-3">
                    <Button color="light-primary" type="submit" block>
                      Procurar
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
          <Row>
            <Col
              lg={4}
              className="d-flex mb-3 align-items-center justify-content-sm-between justify-content-md-start"
            >
              <h5 className="mb-0">
                {dados?.count} Produto{dados?.count > 1 ? 's' : ''}
              </h5>
              <ButtonGroup className="ml-2">
                <Button
                  color="primary"
                  outline
                  onClick={() => changeGrid('list')}
                  active={gridType === 'list'}
                >
                  <HiViewList />
                </Button>
                <Button
                  color="primary"
                  outline
                  onClick={() => changeGrid('card')}
                  active={gridType === 'card'}
                >
                  <HiViewGrid />
                </Button>
              </ButtonGroup>
            </Col>
            <Col lg={8} className="d-flex-wrap justify-content-end">
              {/* <Button
                color="light-success"
                className="mb-3"
                onClick={() => gerarRelatorio()}
              >
                Gerar relatório
              </Button> */}
              {/* <Button color="light-warning" className="mb-3 ml-2">
                Otimizar produtos
              </Button> */}
              <UncontrolledDropdown direction="down" className="mb-3 ml-2">
                <DropdownToggle color="primary">
                  Ação em massa
                  <HiChevronDown />
                </DropdownToggle>
                <DropdownMenu className="w-100">
                  <DropdownItem onClick={() => showOffCanvasMassa('status')}>
                    Altera status
                  </DropdownItem>
                  <DropdownItem onClick={() => showOffCanvasMassa('preco')}>
                    Alterar preço
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <Button
                color="success"
                className="mb-3 ml-2 d-flex align-items-center"
                tag={Link}
                to="/produtos/novo"
              >
                Novo
              </Button>
            </Col>
          </Row>
          <TabContent activeTab={activeTab}>
            <InfiniteScroll
              className="row"
              dataLength={dados?.data?.length || 0}
              hasMore={
                !Object.keys(dados).length ||
                (dados?.data?.length > 0 && dados?.data?.length < dados?.count)
              }
              next={() => {
                loadMore();
              }}
              loader={
                <div className="my-3">
                  <BouncingDotsLoader />
                </div>
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Não há mais dados</b>
                </p>
              }
              scrollableTarget="main-content"
              refreshFunction={() => loadMore(0)}
              pullDownToRefresh={false}
              // pullDownToRefreshThreshold={50}
              // pullDownToRefreshContent={
              //   <h3 style={{ textAlign: 'center' }}>
              //     &#8595; Puxe para baixo para atualizar
              //   </h3>
              // }
              // releaseToRefreshContent={
              //   <h3 style={{ textAlign: 'center' }}>
              //     &#8593; Solte para atualizar
              //   </h3>
              // }
            >
              <Grid
                gridType={gridType}
                dados={dados?.data}
                toggle={(id) => toggleOffcanvas(id)}
              />
            </InfiniteScroll>
          </TabContent>
        </CardBody>
      </Card>

      <Offcanvas
        direction={offcanvas?.direction || 'end'}
        isOpen={!!offcanvas}
        toggle={() => toggleOffcanvas(false)}
      >
        <OffcanvasHeader toggle={() => toggleOffcanvas(false)}>
          {offcanvas?.titulo}
        </OffcanvasHeader>
        <OffcanvasBody>
          <div className="d-flex justify-content-center">
            <img
              height={300}
              className="radius-4 mb-2"
              alt={offcanvas?.titulo}
              src={offcanvas?.ProdutoImagems?.[0]?.Imagem?.path}
            />
          </div>
          <Form
            initialData={offcanvas}
            ref={(ref) => {
              offcanvasRef.current[0] = ref;
            }}
            onSubmit={(e) => handleEdit(e)}
          >
            <Row>
              <Col lg={12}>
                <NumberFormat
                  name="preco_normal"
                  label="Preço normal"
                  className="form-control"
                  prefix="R$"
                />
              </Col>
              <Col lg={12}>
                <NumberFormat
                  name="preco_desconto"
                  label="Preço com desconto"
                  className="form-control"
                  prefix="R$"
                />
              </Col>
              <Col lg={12}>
                <Input
                  label="Estoque disponível"
                  name="estoque_disponivel"
                  type="number"
                  className="form-control"
                />
              </Col>
              <Col lg={12}>
                <Input
                  label="Estoque mínimo"
                  name="estoque_minimo"
                  type="number"
                  className="form-control"
                />
              </Col>
              <Col lg={12}>
                <CheckBox name="aceita_proposta" label="Aceita proposta ?" />
              </Col>
              <Col lg={12}>
                <Input
                  name="taxa_proposta_minima"
                  label="Taxa proposta mínima"
                  type="number"
                  className="form-control"
                />
              </Col>
              {offcanvas?.status !== 'Pendente' && (
                <Col lg={12}>
                  <Select
                    name="status"
                    label="Status"
                    className="form-control"
                    data={['Ativo', 'Inativo']}
                  />
                </Col>
              )}
            </Row>
          </Form>
        </OffcanvasBody>
        <Button
          color="primary"
          className="no-radius py-2"
          onClick={() => {
            offcanvasRef.current[0].submitForm();
          }}
        >
          Alterar
        </Button>
      </Offcanvas>

      <Offcanvas
        direction="end"
        isOpen={offcanvasMassa.show}
        toggle={() =>
          setOffcanvasMassa((old) => ({ show: false, options: old.options }))
        }
      >
        <OffcanvasHeader
          toggle={() =>
            setOffcanvasMassa((old) => ({ show: false, options: old.options }))
          }
        >
          {offcanvasMassa === 'status' ? 'Alterar status' : 'Alterar preço'}
        </OffcanvasHeader>
        <OffcanvasBody>
          <Form
            ref={(ref) => {
              offcanvasRef.current[1] = ref;
            }}
            onSubmit={(e) => handleBulkEdit(e)}
          >
            <Row>
              <Col lg={12}>
                <ReactSelect
                  name="estante"
                  label="Estante"
                  options={offcanvasMassa?.options || []}
                />
              </Col>
              {offcanvasMassa?.tipo === 'status' ? (
                <Col lg={12}>
                  <Select
                    name="status"
                    label="Status"
                    className="form-control"
                    data={['Ativo', 'Inativo']}
                  />
                </Col>
              ) : (
                <>
                  <Col lg={12}>
                    <Select
                      name="ajuste_tipo"
                      label="Aplicar"
                      className="form-control"
                      data={['Desconto', 'Aumento']}
                    />
                  </Col>
                  <Col lg={12}>
                    <Input
                      label="Alterar preço em %"
                      name="ajuste_preco"
                      type="number"
                      className="form-control"
                    />
                  </Col>
                </>
              )}
            </Row>
          </Form>
        </OffcanvasBody>
        <Button
          color="primary"
          className="no-radius py-2"
          onClick={() => {
            offcanvasRef.current[1].submitForm();
          }}
        >
          Alterar
        </Button>
      </Offcanvas>
    </>
  );
}

export default Produtos;
