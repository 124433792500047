import React, { useEffect, useState, useRef } from 'react';
import { FiFilter, FiCameraOff } from 'react-icons/fi';
import {
  Card,
  Col,
  Row,
  InputGroup,
  InputGroupText,
  Button,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  UncontrolledCollapse,
  CardHeader,
  CardBody,
} from 'reactstrap';
import L from 'leaflet';
import {
  MapContainer,
  Marker,
  TileLayer,
  Popup,
  useMap,
  useMapEvent,
} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import _ from 'lodash';
import { useValidator } from '../../hooks/index';
import api from '../../services/api';
// import MarkerImg from '../../assets/images/marker.png';
import MarkerGreenImg from '../../assets/images/marker-green.png';
import MarkerRedImg from '../../assets/images/marker-red.png';
import { Input, Select } from '../../components/unform/index';

import 'leaflet/dist/leaflet.css';

const MyCustomMarker = (type) => {
  const iconOptions = {
    shadowUrl: null,
    iconAnchor: new L.Point(12, 12),
    iconSize: new L.Point(20, 20),
    // Depois muda pra qual ficar melhor
    iconUrl: type === 'Disponivel' ? MarkerGreenImg : MarkerRedImg,
  };

  return L.icon(iconOptions);
};

function Dashboard() {
  const filtrosRef = useRef();
  const debouncePedido = useRef();

  const [filtro, setFiltro] = useState({});
  const { toast } = useValidator();
  const [dados, setDados] = useState(null);

  const [loading, setLoading] = useState(true);

  const [bounds, setBouds] = useState({
    latitude: -14.235004,
    longitude: -51.92528,
    latitudeDelta: 5.26,
    longitudeDelta: 73.98,
  });

  const [activeTab, setActiveTab] = useState('Mapa');
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const createRequestBody = () => {
    const requestBody = { local: bounds, disponibilidade: {}, vaga: {} };

    Object.keys(filtro).forEach((key) => {
      _.set(requestBody, key, filtro[key]);
    });

    return requestBody;
  };

  async function getDados() {
    setLoading(true);
    try {
      const { data } = await api.post(
        '/disponibilidade/garagens',
        createRequestBody()
      );
      setDados(data);
    } catch (err) {
      toast('Erro ao buscar dados!', { type: 'error' });
    }
    setLoading(false);
  }

  let timeoutConsulta;
  function filtrarConsulta(e, value) {
    setFiltro({ ...filtro, [value]: e });
  }
  const debounceConsulta = async (body, value) =>
    new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body, value));
      }, 500);
    });

  useEffect(() => {
    clearTimeout(debouncePedido.current);
    debouncePedido.current = setTimeout(() => {
      getDados();
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds, filtro]);

  const MapEventHandlers = () => {
    const map = useMap();

    useMapEvent('moveend', () => {
      const boundsMap = map.getBounds();

      const { lat: lat1, lng: lng1 } = boundsMap.getSouthWest();
      const { lat: lat2, lng: lng2 } = boundsMap.getNorthEast();

      const newLatitudeDelta = Math.abs(lat2 - lat1);
      const newLongitudeDelta = Math.abs(lng2 - lng1);
      const newCenter = map.getCenter();

      setBouds({
        latitude: newCenter?.lat,
        longitude: newCenter?.lng,
        latitudeDelta: newLatitudeDelta,
        longitudeDelta: newLongitudeDelta,
      });
    });

    return null;
  };

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            active={activeTab === 'Mapa'}
            onClick={() => {
              toggleTab('Mapa');
            }}
          >
            Mapa
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            active={activeTab === 'Dados'}
            onClick={() => {
              toggleTab('Dados');
            }}
          >
            Dados
          </NavLink>
        </NavItem> */}
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="Mapa">
          <Row>
            <div className="p-3 justify-content-end d-flex align-items-center">
              <Button
                color="muted"
                id="toggler"
                className="d-flex align-items-center"
              >
                <FiFilter color="rgba(var(--theme-color-rgb), 0.6)" size={20} />
                &nbsp;&nbsp;Filtros
              </Button>
            </div>
            <div className="filter-collapse px-3 pb-3">
              <UncontrolledCollapse toggler="#toggler">
                <Form ref={filtrosRef}>
                  <Row>
                    <Col>
                      <Select
                        name="status"
                        label="Status vaga"
                        className="form-control"
                        data={[
                          { label: 'Todos', value: '' },
                          { label: 'Disponível', value: 'Disponivel' },
                          { label: 'Locado', value: 'Locado' },
                        ]}
                        onChange={(e) =>
                          debounceConsulta(
                            e.target.value,
                            'disponibilidade.status'
                          )
                        }
                      />
                    </Col>
                    <Col>
                      <Select
                        name="vaga_coberta"
                        label="Vaga coberta"
                        className="form-control"
                        data={[
                          { label: 'Todos', value: '' },
                          { label: 'Sim', value: true },
                          { label: 'Não', value: false },
                        ]}
                        onChange={(e) =>
                          debounceConsulta(e.target.value, 'vaga.vaga_coberta')
                        }
                      />
                    </Col>
                    <Col>
                      <Select
                        name="aceita_moto"
                        label="Vaga aceita moto"
                        className="form-control"
                        data={[
                          { label: 'Todos', value: '' },
                          { label: 'Sim', value: true },
                          { label: 'Não', value: false },
                        ]}
                        onChange={(e) =>
                          debounceConsulta(e.target.value, 'vaga.aceita_moto')
                        }
                      />
                    </Col>
                    <Col>
                      <Select
                        name="vaga_especial"
                        label="Vaga especial"
                        className="form-control"
                        data={[
                          { label: 'Todos', value: '' },
                          { label: 'Sim', value: true },
                          { label: 'Não', value: false },
                        ]}
                        onChange={(e) =>
                          debounceConsulta(e.target.value, 'vaga.vaga_especial')
                        }
                      />
                    </Col>
                    <Col>
                      <Select
                        name="tamanho"
                        label="Tamanho máximo"
                        className="form-control"
                        data={[
                          { label: 'Todos', value: '' },
                          { label: 'Moto', value: 0 },
                          { label: 'Hatch', value: 1 },
                          { label: 'Sedan', value: 2 },
                          { label: 'Suv', value: 3 },
                          { label: 'Pickup', value: 4 },
                        ]}
                        onChange={(e) =>
                          debounceConsulta(e.target.value, 'vaga.tamanho')
                        }
                      />
                    </Col>
                  </Row>
                </Form>
              </UncontrolledCollapse>
            </div>
          </Row>
          <Row>
            <MapContainer
              center={[Number(-14.2400732), Number(-53.1805017)]}
              zoom={5}
              style={{
                width: '100%',
                height: '600px',
              }}
            >
              <MapEventHandlers />
              <TileLayer
                attribution="&copy; CARTO"
                url="https://{s}.basemaps.cartocdn.com/rastertiles/{id}/{z}/{x}/{y}{r}.png"
                subdomains="abcd"
                maxZoom={20}
                id="light_all"
              />
              <MarkerClusterGroup chunkedLoading>
                {dados?.length &&
                  dados?.map((e) => (
                    <Marker
                      key="marker"
                      position={[
                        Number(e?.GaragemVaga?.Garagem?.latitude),
                        Number(e?.GaragemVaga?.Garagem?.longitude),
                      ]}
                      icon={MyCustomMarker(e?.status)}
                    >
                      <Popup className="custom-popup" autoPan={false}>
                        <Row>
                          <Col>
                            <h4 className="text-center">
                              <strong>
                                {e?.GaragemVaga?.identificador_vaga}
                              </strong>
                            </h4>
                          </Col>
                          <hr />
                        </Row>
                        <Row>
                          <Col>
                            <h6 className="text-center">
                              <strong>ID vaga:</strong> {e?.GaragemVaga?.id}
                            </h6>
                          </Col>
                          <hr />
                          <Col>
                            <h6 className="text-center">
                              <strong>ID garagem:</strong>{' '}
                              {e?.GaragemVaga?.id_garagem}
                            </h6>
                          </Col>
                          <hr />
                        </Row>
                        <Row>
                          <Col>
                            <h6 className="text-center">
                              <strong>Status:</strong> {e?.status}
                            </h6>
                          </Col>
                          <Col>
                            <h6 className="text-center">
                              <strong>Valor:</strong>{' '}
                              {Number(
                                e?.GaragemVaga?.valor_mensalidade
                              ).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <h6 className="text-center">
                              <strong>Cobrança:</strong>{' '}
                              {e?.contrato?.frequencia} -{' '}
                              {e?.contrato?.intervalo}
                            </h6>
                          </Col>
                          <hr />
                        </Row>
                        <Row>
                          <Col>
                            <h6 className="text-center">
                              <strong>Endereço:</strong>{' '}
                              {e?.GaragemVaga?.Garagem?.logradouro}, n°{' '}
                              {e?.GaragemVaga?.Garagem?.numero} -{' '}
                              {e?.GaragemVaga?.Garagem?.cidade} /{' '}
                              {e?.GaragemVaga?.Garagem?.estado} /{' '}
                              {e?.GaragemVaga?.Garagem?.pais} -{' '}
                              {e?.GaragemVaga?.Garagem?.cep}
                            </h6>
                          </Col>
                          <hr />
                        </Row>
                        <Row>
                          <Col>
                            <h6 className="text-center">
                              <strong>Lat. Long:</strong>{' '}
                              {e?.GaragemVaga?.Garagem?.latitude},{' '}
                              {e?.GaragemVaga?.Garagem?.longitude}
                            </h6>
                          </Col>
                          <hr />
                        </Row>
                        <Row>
                          {e?.GaragemVaga?.VagaImagems.length ? (
                            e?.GaragemVaga?.VagaImagems.map((foto, index) => (
                              <Col>
                                <a
                                  key={foto?.id}
                                  href={foto?.Imagem?.path}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={foto?.Imagem?.path}
                                    alt={`Foto ${index + 1}`}
                                    className="foto-thumbnail-dashboard"
                                  />
                                </a>
                              </Col>
                            ))
                          ) : (
                            <FiCameraOff size={25} />
                          )}
                        </Row>
                        <hr />
                        <Row className="text-center">
                          <Col>
                            <Link
                              to={{
                                pathname: '/garagens',
                                state: e?.GaragemVaga?.id_garagem,
                              }}
                            >
                              <h6 className="text-primary">
                                Ver garagem da vaga
                              </h6>
                            </Link>
                          </Col>
                        </Row>
                      </Popup>
                    </Marker>
                  ))}
              </MarkerClusterGroup>
            </MapContainer>
            {loading && <h6>Carregando...</h6>}
          </Row>
        </TabPane>
        <TabPane tabId="Dados">
          {/* <form
            onSubmit={(e) => {
              e.preventDefault();
              const data = formFiltros();
              getDados(data);
            }}
          >
            <Row className="mb-3">
              <Col lg={3}>
                <InputGroup className="custom">
                  <InputGroupText>
                    <div>Período</div>
                  </InputGroupText>

                  <Flatpickr
                    label="date ranger"
                    name="periodo"
                    className="form-control"
                    options={{
                      mode: 'range',
                      locale: Portuguese,
                      dateFormat: 'd/m/Y',
                    }}
                  />
                </InputGroup>
              </Col>
              <Col lg={3}>
                <Row>
                  <Col lg={6} sm={6} className="mb-1">
                    <Button
                      color="light-primary"
                      style={{ lineHeight: 2 }}
                      type="submit"
                    >
                      Procurar
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
          <Row>
            <Col>
              <Card>
                <div className="p-3">
                  <h5 className="txt-muted text-center">
                    Valor Pedidos Finalizados
                  </h5>
                  <h2 className="text-center">
                    {Number(
                      dados?.pedidosFinalizadosValor[0]?.total || 0
                    ).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </h2>
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <Card>
                <div className="p-3">
                  <h5 className="txt-muted text-center">Clientes</h5>
                  <h2 className="text-center">{dados?.clientesCount}</h2>
                </div>
              </Card>
            </Col>
            <Col lg={4}>
              <Card>
                <div className="p-3">
                  <h5 className="txt-muted text-center">Parceiros</h5>
                  <h2 className="text-center">{dados?.parceirosCount}</h2>
                </div>
              </Card>
            </Col>
            <Col lg={4}>
              <Card>
                <div className="p-3">
                  <h5 className="txt-muted text-center">Produtos</h5>
                  <h2 className="text-center">{dados?.produtosCount}</h2>
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <Card>
                <div className="p-3">
                  <h5 className="txt-muted text-center">Pedidos finalizados</h5>
                  <h2 className="text-center">
                    {dados?.pedidosFinalizadosCount}
                  </h2>
                </div>
              </Card>
            </Col>
            <Col lg={3}>
              <Card>
                <div className="p-3">
                  <h5 className="txt-muted text-center">Pedidos Entregues</h5>
                  <h2 className="text-center">
                    {dados?.pedidosEntreguesCount}
                  </h2>
                </div>
              </Card>
            </Col>
            <Col lg={3}>
              <Card>
                <div className="p-3">
                  <h5 className="txt-muted text-center">Pedidos cancelados</h5>
                  <h2 className="text-center">
                    {dados?.pedidosCanceladosCount}
                  </h2>
                </div>
              </Card>
            </Col>
            <Col lg={3}>
              <Card>
                <div className="p-3">
                  <h5 className="txt-muted text-center">Pedidos reembolso</h5>
                  <h2 className="text-center">
                    {dados?.pedidosReembolsoCount}
                  </h2>
                </div>
              </Card>
            </Col>
          </Row> */}
        </TabPane>
      </TabContent>
    </div>
  );
}

export default Dashboard;
