/* eslint-disable no-nested-ternary */
import axios from 'axios';

const api = axios.create({
  // baseURL: 'http://localhost:3333',
  baseURL: window.location.origin.includes('http://localhost')
    ? 'http://localhost:3333'
    : 'https://api.easygarage.com.br/',
});

export default api;
