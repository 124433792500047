import React, { useEffect, useRef, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
} from 'reactstrap';
import { Form } from '@unform/web';
import { Input, Select } from '../../components/unform/index';
import api from '../../services/api';
import { useValidator } from '../../hooks';

function formatarDataHora(d) {
  const data = new Date(d);
  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0');
  const ano = String(data.getFullYear()).padStart(4, '0');
  const horas = String(data.getHours()).padStart(2, '0');
  const minutos = String(data.getMinutes()).padStart(2, '0');

  return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
}

function formatMinutesToHours(minutes) {
  const hours = Math.floor(minutes / 60); // Obtém a parte inteira das horas
  const remainingMinutes = minutes % 60; // Obtém o restante dos minutos

  // Formatação para exibir sempre 2 dígitos para as horas e minutos
  const formattedHours = `0${hours}`.slice(-2);
  const formattedMinutes = `0${remainingMinutes}`.slice(-2);

  return `${formattedHours}:${formattedMinutes}`;
}

const InfosComponent = ({ id }) => {
  const { showLoader, closeLoader, toast } = useValidator();

  const filtrosRef = useRef();

  const [filtro, setFiltro] = useState({});
  const [dados, setDados] = useState([]);

  async function getDados(page) {
    showLoader();
    try {
      const params = Object.keys(filtro)
        .map((e) => {
          if (e === 'page') {
            if (Number.isNaN(filtro[e])) {
              return `${e}=${1}`;
            }
          }
          return `${e}=${filtro[e]}`;
        })
        .join('&');

      const { data } = await api.get(
        `/historico/vagaveiculo?page=${
          page || 1
        }&id_vaga_veiculo=${id}&${params}`
      );

      console.log(data);
      setDados(data);
    } catch (err) {
      toast('Erro ao buscar dados!', { type: 'error0' });
    }
    closeLoader();
  }

  useEffect(() => {
    if (id) {
      getDados();
    }
  }, [filtro]);

  let timeoutConsulta;
  function filtrarConsulta(e, value) {
    setFiltro({ ...filtro, [value]: e });
  }
  const debounceConsulta = async (body, value) =>
    new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body, value));
      }, 500);
    });

  return (
    <Card>
      <div className="p-3 justify-content-between d-flex align-items-center">
        <h6 className="txt-muted">Total de registros: {dados?.count || 0}</h6>
        <Button
          color="muted"
          id="toggler"
          className="d-flex align-items-center"
        >
          <FiFilter color="rgba(var(--theme-color-rgb), 0.6)" size={20} />
          &nbsp;&nbsp;Filtros
        </Button>
      </div>
      <div className="filter-collapse px-3 pb-3">
        <UncontrolledCollapse toggler="#toggler">
          <Form onSubmit={(data) => console.log(data)} ref={filtrosRef}>
            <Row>
              <Col>
                <Input
                  label="Data"
                  className="form-control"
                  placeholder="Digite a data"
                  name="data"
                  type="date"
                  onChange={(e) => debounceConsulta(e.target.value, 'data')}
                />
              </Col>
              <Col>
                <Input
                  label="Motivo"
                  className="form-control"
                  placeholder="Digite o motivo"
                  name="motivo"
                  onChange={(e) => debounceConsulta(e.target.value, 'motivo')}
                />
              </Col>
              <Col>
                <Select
                  label="Tipo"
                  className="form-control"
                  name="tipo"
                  onChange={(e) => debounceConsulta(e.target.value, 'tipo')}
                  data={[
                    {
                      value: '',
                      label: 'Todos',
                    },
                    {
                      value: 'Registro',
                      label: 'Registro',
                    },
                    {
                      value: 'Pagamento',
                      label: 'Pagamento',
                    },
                    {
                      value: 'Reserva',
                      label: 'Reserva',
                    },
                  ]}
                />
              </Col>
              <Col>
                <Input
                  label="Status"
                  className="form-control"
                  placeholder="Digite o status"
                  name="status"
                  onChange={(e) => debounceConsulta(e.target.value, 'status')}
                />
              </Col>
            </Row>
          </Form>
        </UncontrolledCollapse>
      </div>

      <Table className="rwd-table" responsive>
        <thead>
          <tr>
            <th>Data</th>
            <th>Tipo</th>
            <th>Motivo</th>
            <th>Entrada</th>
            <th>Saida</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {dados?.data?.map((m) => (
            <>
              <tr key={m?.id}>
                <td data-label="Data">{formatarDataHora(m?.data)}</td>
                <td data-label="Tipo">{m?.tipo}</td>
                <td data-label="Motivo">{m?.motivo}</td>
                <td data-label="Entrada">
                  {m?.tipo === 'Registro' && m?.hora_entrada ? (
                    <div style={{ display: 'flex' }}>
                      <div className="mr-1">
                        <strong>Horário: </strong>

                        {m?.hora_entrada
                          ? formatMinutesToHours(m?.hora_entrada)
                          : 'N/A'}
                      </div>
                      <div className="mr-1">
                        <strong>Lat. Long.: </strong>
                        {m?.latitude_entrada}, {m?.longitude_entrada}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </td>
                <td data-label="Saida">
                  {m?.tipo === 'Registro' && m?.hora_saida ? (
                    <div style={{ display: 'flex' }}>
                      <div className="mr-1">
                        <strong>Horário: </strong>

                        {m?.hora_saida
                          ? formatMinutesToHours(m?.hora_saida)
                          : 'N/A'}
                      </div>
                      <div className="mr-1">
                        <strong>Lat. Long.: </strong>
                        {m?.latitude_saida}, {m?.longitude_saida}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </td>
                <td data-label="Status">{m?.status}</td>
              </tr>
            </>
          ))}
        </tbody>
      </Table>
      <Pagination
        nextLabel={<FaChevronRight />}
        previousLabel={<FaChevronLeft />}
        pageCount={dados?.pages || 1}
        onPageChange={({ selected }) => getDados(selected + 1)}
        initialPage={0}
        containerClassName="pagination justify-end mr-1"
        activeClassName="active"
      />
    </Card>
  );
};

export default InfosComponent;
