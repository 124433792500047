import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { InputGroup as Group } from 'reactstrap';

export default function InputGroup({
  name,
  label,
  type,
  prepend,
  append,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => ref.current.value,
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);
  return (
    <div className="form-group">
      <label htmlFor={fieldName} className="form-label">
        {label}
      </label>
      <Group
        className={error ? 'error flex-wrap-inherit' : 'flex-wrap-inherit'}
      >
        {prepend && (
          <div className="ml-3 d-flex justify-content-center align-items-center">
            {prepend}
          </div>
        )}

        <input
          ref={inputRef}
          defaultValue={defaultValue}
          type={type || 'text'}
          {...rest}
        />
        {append && (
          <div className="mr-3 d-flex justify-content-center align-items-center">
            {append}
          </div>
        )}
      </Group>
      {error && <small className="error">{error}</small>}
    </div>
  );
}
