import React from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import { useAuth } from '../contexts/auth';

import AuthRoute from './authRoute';

import Produtos from '../pages/Produtos';
import CriarProduto from '../pages/Produtos/criar';
import Pedidos from '../pages/Pedidos';
import Perfil from '../pages/Perfil';
import Ativacao from '../pages/Ativacao';
import Campanha from '../pages/Campanha';
import Parceiros from '../pages/Parceiros';
import Atrasos from '../pages/Atrasos';
import Repasses from '../pages/Repasses';

import Layout from '../layouts';

import Login from '../pages/Login/index';
import Dashboard from '../pages/Dashboard/index';
import Push from '../pages/Push';
import EmailMarketing from '../pages/EmailMarketing';
import ListarImagens from '../pages/Imagens';
import Usuarios from '../pages/Usuarios';
import Garagens from '../pages/Garagens';
import Veiculos from '../pages/Veiculos';
import Locacoes from '../pages/Locacoes';
import Contratos from '../pages/Contratos';
import RetornoWebhook from '../pages/RetornoWebhook';
import Taxa from '../pages/Taxa';

const Routes = () => {
  const { loading, signed } = useAuth();
  return (
    <BrowserRouter>
      <Switch>
        {loading || signed ? (
          <>
            <Layout>
              <Switch>
                <AuthRoute path="/" exact component={Dashboard} />
                <AuthRoute path="/usuarios" exact component={Usuarios} />
                <AuthRoute path="/garagens" exact component={Garagens} />
                <AuthRoute path="/push" exact component={Push} />
                <AuthRoute path="/imagens" exact component={ListarImagens} />
                <AuthRoute path="/veiculos" exact component={Veiculos} />
                <AuthRoute path="/locacoes" exact component={Locacoes} />
                <AuthRoute path="/contratos" exact component={Contratos} />
                <AuthRoute
                  path="/retornowebhook"
                  exact
                  component={RetornoWebhook}
                />
                <AuthRoute path="/taxa" exact component={Taxa} />
                <AuthRoute path="/repasses" exact component={Repasses} />
                <Redirect to="/" />
              </Switch>
            </Layout>
          </>
        ) : (
          <>
            <Route path="/" exact component={Login} />
            <Redirect to="/" />
          </>
        )}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
