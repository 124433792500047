import React, { useEffect, useRef, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Form } from '@unform/web';
import { Input, Select } from '../../components/unform/index';
import api from '../../services/api';
import { useValidator } from '../../hooks';

function Contratos() {
  const formRef = useRef();
  const { showLoader, closeLoader, toast } = useValidator();

  const [modal, setModal] = useState(false);
  const [dados, setDados] = useState([]);

  const toggleModal = () => setModal(!modal);

  async function getDados() {
    showLoader();
    try {
      const { data } = await api.get('/contratos/admin');

      setDados(data);
    } catch (err) {
      toast('Erro ao buscar dados!', { type: 'error' });
    }
    closeLoader();
  }

  useEffect(() => {
    getDados();
  }, []);

  async function handleSubmit(body) {
    try {
      showLoader();
      await api.post('/contrato', body);
      getDados();
      toggleModal();
    } catch (err) {
      toast.error(err.mensagem);
    }
    closeLoader();
  }

  return (
    <>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">
            Total de registros: {dados?.length || 0}
          </h6>
          <Button
            onClick={toggleModal}
            color="primary"
            className="d-flex align-items-center"
          >
            <FiPlus color="white" size={20} />
            &nbsp;&nbsp;Novo
          </Button>
        </div>

        <Table className="rwd-table" responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>Frequência</th>
              <th>Intervalo</th>
              {/* <th className="text-center">Ação</th> */}
            </tr>
          </thead>
          <tbody>
            {dados?.map((m) => (
              <tr key={m?.id}>
                <td data-label="ID">{m?.id}</td>
                <td data-label="Frequência">{m?.frequencia}</td>
                <td data-label="Intervalo">{m?.intervalo}</td>
                {/* <td
                  data-label="Action"
                  className="d-flex justify-content-lg-end justify-content-sm-between"
                >
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color="link"
                      className="text-decoration-none padding-0-5"
                    >
                      <FiSettings className="mr-2" /> <FaChevronDown />
                    </DropdownToggle>
                    <DropdownMenu end>
                      <DropdownItem
                        onClick={() => ativaInativa(m?.id, m?.status)}
                      >
                        <FiToggleLeft className="mr-1" />
                        Inativar/Ativar
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td> */}
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>

      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Novo contrato interno</ModalHeader>
        <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
          <ModalBody>
            <Row className="text-center">
              <Col>
                <span>Exemplos: (Precisa ser assim por causa da ZOOP)</span>
                <h6>
                  <strong>MENSAL</strong> (Frequencia: Mensal, Intervalo: 1)
                </h6>
                <h6>
                  <strong>TRIMESTRAL</strong> (Frequencia: Mensal, Intervalo: 3)
                </h6>
                <h6>
                  <strong>ANUAL</strong> (Frequencia: Anual, Intervalo: 1)
                </h6>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <Select
                  name="frequencia"
                  label="Frequência *"
                  className="form-control"
                  data={[
                    { label: 'Diario', value: 'Diario' },
                    { label: 'Mensal', value: 'Mensal' },
                    { label: 'Semanal', value: 'Semanal' },
                    { label: 'Anual', value: 'Anual' },
                  ]}
                />
              </Col>
              <Col>
                <Input
                  label="Intervalo *"
                  name="intervalo"
                  className="form-control"
                  type="number"
                />
              </Col>
              <Col>
                <Input
                  label="Garagem ID"
                  name="id_garagem"
                  className="form-control"
                  type="text"
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Confirmar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default Contratos;
