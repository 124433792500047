import React, { useEffect, useRef, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import {
  FaArrowAltCircleDown,
  FaArrowCircleUp,
  FaChevronLeft,
  FaChevronRight,
} from 'react-icons/fa';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
} from 'reactstrap';
import { Form } from '@unform/web';
import ReactJson from 'react-json-view';
import { Input, Select } from '../../components/unform/index';
import api from '../../services/api';
import { useValidator } from '../../hooks';

function formatarDataHora(d) {
  const data = new Date(d);
  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0');
  const ano = String(data.getFullYear()).padStart(4, '0');
  const horas = String(data.getHours()).padStart(2, '0');
  const minutos = String(data.getMinutes()).padStart(2, '0');

  return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
}

const InfosComponent = ({ id }) => {
  const { showLoader, closeLoader, toast } = useValidator();

  const filtrosRef = useRef();

  const [filtro, setFiltro] = useState({});
  const [dados, setDados] = useState([]);

  const [expandedRow, setExpandedRow] = useState(null);

  const handleRowClick = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  async function getDados(page) {
    showLoader();
    try {
      const params = Object.keys(filtro)
        .map((e) => {
          if (e === 'page') {
            if (Number.isNaN(filtro[e])) {
              return `${e}=${1}`;
            }
          }
          return `${e}=${filtro[e]}`;
        })
        .join('&');

      const { data } = await api.get(
        `/pagamentos/vagaveiculo?page=${
          page || 1
        }&id_vaga_veiculo=${id}&${params}`
      );

      setDados(data);
    } catch (err) {
      toast('Erro ao buscar dados!', { type: 'error0' });
    }
    closeLoader();
  }

  useEffect(() => {
    if (id) {
      getDados();
    }
  }, [filtro]);

  let timeoutConsulta;
  function filtrarConsulta(e, value) {
    setFiltro({ ...filtro, [value]: e });
  }
  const debounceConsulta = async (body, value) =>
    new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body, value));
      }, 500);
    });

  return (
    <Card>
      <div className="p-3 justify-content-between d-flex align-items-center">
        <h6 className="txt-muted">Total de registros: {dados?.count || 0}</h6>
        <Button
          color="muted"
          id="toggler"
          className="d-flex align-items-center"
        >
          <FiFilter color="rgba(var(--theme-color-rgb), 0.6)" size={20} />
          &nbsp;&nbsp;Filtros
        </Button>
      </div>
      <div className="filter-collapse px-3 pb-3">
        <UncontrolledCollapse toggler="#toggler">
          <Form onSubmit={(data) => console.log(data)} ref={filtrosRef}>
            <Row>
              <Col>
                <Input
                  label="Data"
                  className="form-control"
                  placeholder="Digite a data"
                  name="data"
                  type="date"
                  onChange={(e) => debounceConsulta(e.target.value, 'data')}
                />
              </Col>
              <Col>
                <Input
                  label="Motivo"
                  className="form-control"
                  placeholder="Digite o motivo"
                  name="motivo"
                  onChange={(e) => debounceConsulta(e.target.value, 'motivo')}
                />
              </Col>
              <Col>
                <Select
                  label="Tipo"
                  className="form-control"
                  name="tipo"
                  onChange={(e) => debounceConsulta(e.target.value, 'tipo')}
                  data={[
                    {
                      value: '',
                      label: 'Todos',
                    },
                    {
                      value: 'Registro',
                      label: 'Registro',
                    },
                    {
                      value: 'Pagamento',
                      label: 'Pagamento',
                    },
                    {
                      value: 'Reserva',
                      label: 'Reserva',
                    },
                  ]}
                />
              </Col>
              <Col>
                <Input
                  label="Status"
                  className="form-control"
                  placeholder="Digite o status"
                  name="status"
                  onChange={(e) => debounceConsulta(e.target.value, 'status')}
                />
              </Col>
            </Row>
          </Form>
        </UncontrolledCollapse>
      </div>

      <Table className="rwd-table" responsive>
        <thead>
          <tr>
            <th>Data</th>
            <th>Valor</th>
            <th className="text-center">Mês</th>
            <th className="text-center">Ano</th>
            {/* <th>Id Invoice Zoop</th> */}
            {/* <th>Id Assinatura Zoop</th> */}
            <th>Data Pagamento</th>
            <th>Status</th>
            <th className="text-center">Obj Retorno</th>
          </tr>
        </thead>
        <tbody>
          {dados?.data?.map((m, index) => (
            <>
              <tr key={m?.id}>
                <td data-label="Data">{formatarDataHora(m?.createdAt)}</td>
                <td data-label="Valor">
                  {Number(m?.valor).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </td>
                <td data-label="Mês" className="text-center">
                  {m?.mes_mensalidade}
                </td>
                <td data-label="Ano" className="text-center">
                  {m?.ano_mensalidade}
                </td>
                {/* <td data-label="Id Invoice Zoop" className="text-center">
                  {m?.id_assinatura_pagamento}
                </td> */}
                {/* <td data-label="Id Assinatura Zoop">{m?.id_invoice}</td> */}
                <td data-label="Id Invoice Zoop">
                  {m?.data_pagamento
                    ? formatarDataHora(m?.data_pagamento)
                    : 'N/A'}
                </td>
                <td data-label="Status">{m?.status_pagamento}</td>
                <td data-label="Obj Retorno" className="text-center">
                  <Button
                    color="primary-outline"
                    type="button"
                    onClick={() => handleRowClick(index)}
                  >
                    {expandedRow === index ? (
                      <FaArrowCircleUp />
                    ) : (
                      <FaArrowAltCircleDown />
                    )}
                  </Button>
                </td>
              </tr>
              {expandedRow === index && (
                <tr>
                  <td colSpan="8">
                    <div>
                      <ReactJson
                        displayObjectSize={false}
                        displayDataTypes={false}
                        src={m?.obj_retorno_zoop}
                        style={{ width: '1000px' }}
                      />
                    </div>
                  </td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </Table>
      <Pagination
        nextLabel={<FaChevronRight />}
        previousLabel={<FaChevronLeft />}
        pageCount={dados?.pages || 1}
        onPageChange={({ selected }) => getDados(selected + 1)}
        initialPage={0}
        containerClassName="pagination justify-end mr-1"
        activeClassName="active"
      />
    </Card>
  );
};

export default InfosComponent;
