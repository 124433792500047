import React, { useEffect, useRef, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import {
  FaChevronLeft,
  FaChevronRight,
  FaArrowAltCircleDown,
  FaArrowCircleUp,
} from 'react-icons/fa';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
} from 'reactstrap';
import { Form } from '@unform/web';
import ReactJson from 'react-json-view';
import { Input, Select } from '../../components/unform/index';
import api from '../../services/api';
import { useValidator } from '../../hooks';

function formatarDataHora(d) {
  const data = new Date(d);
  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0');
  const ano = String(data.getFullYear()).padStart(4, '0');
  const horas = String(data.getHours()).padStart(2, '0');
  const minutos = String(data.getMinutes()).padStart(2, '0');

  return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
}

function RetornoWebhook() {
  const { showLoader, closeLoader, toast } = useValidator();

  const filtrosRef = useRef();

  const [filtro, setFiltro] = useState({});
  const [dados, setDados] = useState([]);

  const [expandedRow, setExpandedRow] = useState(null);

  const handleRowClick = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  async function getDados(page) {
    showLoader();
    try {
      const params = Object.keys(filtro)
        .map((e) => {
          if (e === 'page') {
            if (Number.isNaN(filtro[e])) {
              return `${e}=${1}`;
            }
          }
          return `${e}=${filtro[e]}`;
        })
        .join('&');

      const { data } = await api.get(
        `/retornowebhook?page=${page || 1}&${params}`
      );

      setDados(data);
    } catch (err) {
      toast('Erro ao buscar dados!', { type: 'error0' });
    }
    closeLoader();
  }

  useEffect(() => {
    getDados();
  }, [filtro]);

  let timeoutConsulta;
  function filtrarConsulta(e, value) {
    setFiltro({ ...filtro, [value]: e });
  }
  const debounceConsulta = async (body, value) =>
    new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body, value));
      }, 500);
    });

  return (
    <>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {dados?.count || 0}</h6>
          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter color="rgba(var(--theme-color-rgb), 0.6)" size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
        </div>
        <div className="filter-collapse px-3 pb-3">
          <UncontrolledCollapse toggler="#toggler">
            <Form onSubmit={() => {}} ref={filtrosRef}>
              <Row>
                <Col lg={2}>
                  <Input
                    label="Data"
                    className="form-control"
                    placeholder="Digite a data"
                    name="data"
                    type="date"
                    onChange={(e) => debounceConsulta(e.target.value, 'data')}
                  />
                </Col>
                <Col>
                  <Input
                    label="Tipo"
                    className="form-control"
                    placeholder="Digite o tipo"
                    name="tipo"
                    onChange={(e) => debounceConsulta(e.target.value, 'tipo')}
                  />
                </Col>
                <Col>
                  <Input
                    label="Tipo interno"
                    className="form-control"
                    placeholder="Digite o tipo interno"
                    name="tipo_interno"
                    onChange={(e) =>
                      debounceConsulta(e.target.value, 'tipo_interno')
                    }
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>

        <Table className="rwd-table" responsive>
          <thead>
            <tr>
              <th>Tipo interno</th>
              <th>Tipo</th>
              <th>Data</th>
              <th className="text-center">Obj</th>
            </tr>
          </thead>
          <tbody>
            {dados?.data?.map((m, index) => (
              <>
                <tr key={m?.id}>
                  <td data-label="Tipo interno">{m?.tipo_interno}</td>
                  <td data-label="Tipo">{m?.tipo}</td>
                  <td data-label="Data">{formatarDataHora(m?.createdAt)}</td>
                  <td data-label="Obj" className="text-center">
                    <Button
                      color="primary-outline"
                      type="button"
                      onClick={() => handleRowClick(index)}
                    >
                      {expandedRow === index ? (
                        <FaArrowCircleUp />
                      ) : (
                        <FaArrowAltCircleDown />
                      )}
                    </Button>
                  </td>
                </tr>
                {expandedRow === index && (
                  <tr>
                    <td colSpan="4">
                      <div>
                        <ReactJson
                          displayObjectSize={false}
                          displayDataTypes={false}
                          src={m?.objeto}
                          style={{ width: '1000px' }}
                        />
                      </div>
                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados?.pages || 1}
          onPageChange={({ selected }) => getDados(selected)}
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
    </>
  );
}

export default RetornoWebhook;
